import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { KeyboardBackspace } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import Auth from './Auth'
import { Navbar, Button, Avatar } from '@material-tailwind/react'
import type { NavbarProps } from '@material-tailwind/react'
import type { TypographyProps } from '@material-tailwind/react'
// import { useAppSelector } from '../../src/store/store'
// import { ITranscript } from '../interfaces'
import DialogComponent from './Dialog'
import { MenuDefault } from './Menu'
import Drawer from './Sidebar/Drawer'
import Typography from './Typography/Typography'
import { useAppDispatch, useAppSelector } from '../store/store'
import { helperPlanData } from '../store/features/planSlice'
import UserProfileAvatar from './UserProfileAvatar'
import { IUser } from '../interfaces'
import AddDialog from './Projects/AddDialog'
import { addMember } from '../store/features/projectSlice'
import CustomTooltip from './Tooltip/Tooltip'
import { customEvent } from '../utils/customHooks'
// import { setShowUpgradeModal } from '../store/features/userSlice'
import { setSelectedContent } from '../store/features/contentSlice'
import { KeywordDialogRef } from '../pages/Keywords'

interface IStateProps {
  isEnterprise?: boolean
  userId?: string
  childRef?: KeywordDialogRef
}

const Header = ({
  isEnterprise,
  userId,
  childRef,
}: IStateProps & TypographyProps & NavbarProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const { user, userData, userStatus } = useAppSelector((store) => store.user)
  const {
    currentProject,
    roles,
    // , loading
  } = useAppSelector((store) => store.project)
  const { keywords } = useAppSelector((store) => store.keyword)
  const { members, currentAccount } = useAppSelector((store) => store.account)
  const [filteredMembers, setFilteredMembers] = useState<IUser[]>([])
  // const [projectMemberRole, setProjectMemberRole] = useState<string[]>([])
  const [joinedMembers, setJoinedMembers] = useState<
    Array<{ id: string; email: string; name: string; role: string }>
  >([])
  const [unFilteredMembers, setUnFilteredMembers] = useState<IUser[]>([])
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const location = useLocation()
  const { plans } = useAppSelector((store) => store.plan)

  const userAccount = currentAccount.users
    ? currentAccount.users[String(userData.id)]
    : null

  const handleOpenAdd = (): void => {
    setOpenAddDialog(!openAddDialog)
  }

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const currentUserPlan = useMemo(() => {
    if (plans.length > 0 && userStatus) {
      const userPlan = plans.find(
        (plan) => plan?.planID === userStatus?.plan?.product,
      )
      return userPlan
    }
    return null
  }, [plans, userStatus])

  const navToDash = (): void => {
    dispatch(setSelectedContent({}))

    navigate('/content-marketing') // This navigates back
    //history.push('/transcripts')
  }
  // const transcript = useAppSelector((store: { paragraphsSlice: ITranscript }) => store.paragraphsSlice)
  const { t } = useTranslation()

  useEffect(() => {
    helperPlanData({ dispatch })
  }, [])

  useEffect(() => {
    if (
      location.pathname.includes('/project/') &&
      Object.keys(currentProject).length !== 0
    ) {
      const users = members.filter((member) => {
        return currentProject?.users[member.id!]
      })
      const unfilteredUsers = members.filter((member) => {
        return !currentProject.users[member.id!]
      })
      const detailedFilteredMembers = users.map((member) => {
        return {
          id: member.id!,
          email: member.email!,
          name: member.name!,
          role: currentProject?.users[member.id!].role,
        }
      })
      // const currentProjectUser = currentProject?.users[user.uid!]
      // const memberRole = roles[currentProjectUser?.role]?.['member']

      setFilteredMembers(users)
      setUnFilteredMembers(unfilteredUsers)
      setJoinedMembers(detailedFilteredMembers)
      // setProjectMemberRole(memberRole)
    }
  }, [currentProject, location.pathname, members, roles, user])

  const handleAddMember = async (
    projectId: string,
    memberId: string,
    role: string,
  ): Promise<void> => {
    await addMember(projectId, memberId, role)
    customEvent('project_collaborator_invited', {
      accountId: currentAccount.id,
      projectId: projectId,
      userRole: role,
      user_userId: userData.id,
    })
  }

  const handleOpen = (): void => {
    if (!open) {
      customEvent('demo_request_modal_opened', {
        category: 'SalesAndSupport Events',
        accountId: currentAccount.id,
        user_userId: userId,
        projectId: currentProject?.id,
        actionSource: 'navbar-cta',
      })
    }
    setOpen(!open)
  }

  const handleBackClick = () => {
    // Extract the keywordId from the path
    const match = path.match(/^\/keyword\/([^/]+)\/[^/]+$/)
    if (match && match[1]) {
      const keywordId = match[1]
      // Navigate to /keyword/:keywordId with a query parameter
      navigate(`/keyword/${keywordId}?tab=violation`)
    }
  }
  // const handleUpgrade = (): void => {
  //   customEvent('billing_upgrade_modal_opened', {
  //     category: 'accounts',
  //     accountId: currentAccount.id,
  //     user_userId: userId,
  //     projectId: currentProject.id,
  //     actionSource: 'navbar-cta',
  //   })
  //   dispatch(setShowUpgradeModal(true))
  // }
  const path = location.pathname

  const _checkLocation = useCallback(() => {
    if (path.includes('social-replies')) return t('sidebar.socialReplies')
    else if (path.includes('content-marketing'))
      return t('sidebar.contentMarketing')
    else if (path.includes('directories-listing'))
      return t('sidebar.directoriesListing')
    else if (path.includes('integrations')) return t('sidebar.integrations')
    else if (path.includes('project-settings'))
      return t('sidebar.projectSettings')
    else return path
  }, [path])

  const _handleNavigate = useCallback(() => {
    navigate('/keywords')
  }, [navigate])

  const isEditKeywordRoute = useMemo(() => {
    return path.includes('/keyword/') && path.includes('edit')
  }, [path])
  // if (loading === 'idle') {
  //   return <></>
  // }

  return (
    //     <header className="org-color-dark header-green-lstn">
    //       <svg height="17" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 55.9 20" enableBackground="new 0 0 55.9 20" xmlSpace="preserve">
    //         {/* <g>
    //                   <rect x="0" y="0.4" fill="#FFFFFF" width="6.9" height="19.6" />
    //                   <rect x="19.6" y="0.4" fill="#FFFFFF" width="6.9" height="19.6" />
    //                   <rect x="35.1" y="0.4" fill="#FFFFFF" width="6.9" height="19.6" />
    //                   <ellipse fill="#FFFFFF" cx="30.8" cy="3.9" rx="3.9" ry="3.9" />
    //                   <path
    //                     fill="#FFFFFF"
    //                     d="M50.5,11.1c-0.4-0.7-0.4-1.1,0-1.8l5.4-8.9h-7.5c0,0-4.5,7.4-5.2,8.4c-0.6,1-0.6,1.7,0,2.7
    // 		c0.6,1.1,5.1,8.4,5.1,8.4h7.5C55.9,20,50.6,11.2,50.5,11.1z"
    //                   />
    //                   <path fill="#FFFFFF" d="M15.5,3.5c-0.4-1.8-1.9-3.1-3.8-3.1l0,0H7.3L11.7,20h7.5L15.5,3.5z" />
    //                 </g> */}
    //       </svg>
    //       <h1 className="org-text-l logo">
    //         <button onClick ={navToDash} className="lstn-logo-header"> LSTN {process.env.NODE_ENV === "development" ? "(development)" : ""}</button>
    //         {location.pathname.includes('/transcript/') && (
    //           <button className="org-btn transcript-action-btn header-nav-btn" onClick={navToDash}>
    //             <KeyboardBackspace />
    //             {t('header.back')}
    //           </button>
    //         )}
    //       </h1>
    //  {/* @ts-ignore */}
    //       <Auth isEnterprise={isEnterprise} />
    //     </header>
    <>
      <Navbar
        className="sticky top-0 z-10 max-w-full pl-4 md:px-8 shadow py-2 md:py-4 rounded-none md:shadow-none border-b-0"
        placeholder={undefined}
      >
        <div className="flex items-center justify-between text-blue-gray-900">
          <div className="flex items-center gap-4">
            <div className="flex lg:hidden">
              <Drawer />
            </div>
            <div
              className={`items-center gap-2 flex cursor-pointer ${
                location.pathname.includes('/settings') ||
                location.pathname.includes('/account-settings') ||
                location.pathname.includes('/admin-dashboard')
                  ? 'flex'
                  : 'lg:hidden'
              }`}
              onClick={_handleNavigate}
            >
              <div>
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="26"
                    width="26"
                    height="26"
                    rx="4"
                    transform="rotate(-90 0 26)"
                    fill="#2196F3"
                  />
                  <path
                    d="M8.83998 14.5601C7.26278 14.5601 5.97998 15.8439 5.97998 17.422C5.97998 18.998 7.26293 20.2801 8.83998 20.2801C10.417 20.2801 11.7 18.9978 11.7 17.422C11.7 15.8439 10.4169 14.5601 8.83998 14.5601Z"
                    fill="white"
                  />
                  <path
                    d="M17.1572 14.5601C15.5818 14.5601 14.3 15.8439 14.3 17.422C14.3 18.9978 15.5818 20.2801 17.1572 20.2801C18.7358 20.2801 20.02 18.9978 20.02 17.422C20.02 15.8439 18.7357 14.5601 17.1572 14.5601Z"
                    fill="white"
                  />
                  <path
                    d="M6.48163 12.74H8.87743C8.87772 12.74 8.87787 12.74 8.87816 12.74C8.87846 12.74 8.87875 12.74 8.87904 12.74H17.119C17.1193 12.74 17.1196 12.74 17.1199 12.74C17.1202 12.74 17.1203 12.74 17.1206 12.74H19.5182C19.7954 12.74 20.02 12.5197 20.02 12.2481C20.02 11.9764 19.7954 11.7561 19.5182 11.7561H17.5286L16.3282 6.11152C16.3257 6.10034 16.321 6.09045 16.3179 6.07969C16.3121 6.05919 16.3068 6.0384 16.2983 6.01905C16.2912 6.0027 16.2815 5.98808 16.2728 5.97274C16.2656 5.96026 16.2596 5.94693 16.2514 5.93517C16.2415 5.92084 16.2295 5.90865 16.2179 5.8956C16.2084 5.88442 16.1996 5.87238 16.189 5.8622C16.1789 5.85245 16.1672 5.84456 16.1562 5.83567C16.142 5.82406 16.1287 5.81202 16.1134 5.80212C16.1045 5.79639 16.0948 5.79252 16.0858 5.78736C16.0667 5.7766 16.048 5.76571 16.0277 5.75753C16.0186 5.75395 16.0091 5.75223 16.0001 5.74908C15.9787 5.74176 15.9574 5.73416 15.9349 5.72986C15.9183 5.72671 15.9011 5.72642 15.884 5.72485C15.8682 5.72312 15.853 5.71997 15.8368 5.71997H10.1632C10.1531 5.71997 10.1438 5.72226 10.1338 5.72298C10.1106 5.72427 10.0872 5.72542 10.0647 5.72972C10.0468 5.73316 10.0303 5.73961 10.013 5.74492C9.99916 5.74922 9.98483 5.75209 9.97152 5.75753C9.95427 5.76456 9.93863 5.77402 9.92254 5.78277C9.91041 5.78936 9.89769 5.79467 9.88599 5.80227C9.87254 5.81101 9.86099 5.82177 9.84856 5.83166C9.83584 5.84184 9.82239 5.85101 9.81055 5.86234C9.80104 5.87166 9.79329 5.88256 9.78452 5.89245C9.77224 5.9065 9.75923 5.91983 9.74855 5.93517C9.74066 5.9465 9.7351 5.95926 9.72808 5.97116C9.71902 5.98693 9.70908 6.00199 9.70177 6.0189C9.69372 6.03726 9.6889 6.05719 9.6832 6.07654C9.67983 6.0883 9.67457 6.09905 9.67194 6.11124L8.4698 11.756H6.48163C6.20456 11.756 5.97998 11.9762 5.97998 12.2479C5.97998 12.5196 6.20456 12.74 6.48163 12.74Z"
                    fill="white"
                  />
                </svg>
              </div>
              <div className="flex">
                <Typography className="text-blue-gray-900 text-base font-bold">
                  Ad
                </Typography>
                <Typography className="text-blue-gray-900 text-base font-normal">
                  lertiser
                </Typography>
              </div>
            </div>
            {location.pathname.includes('/content-marketing/') ||
            location.pathname.includes('/project/folder/') ? (
              <>
                <div className="flex">
                  <Typography className="text-well_primary-500 text-xl font-bold">
                    Ad
                  </Typography>
                  <Typography className="text-well_primary-500 text-xl font-normal">
                    lertiser
                  </Typography>
                </div>
                <button
                  className="org-btn transcript-action-btn header-nav-btn"
                  onClick={navToDash}
                >
                  <KeyboardBackspace />
                  {t('header.back')}
                </button>
              </>
            ) : path.includes('/keyword/') &&
              !isEditKeywordRoute &&
              path.match(/^\/keyword\/[^/]+\/[^/]+$/) ? (
              <div className="flex flex-row items-center">
                <Link to="/keywords">
                  <Typography className="text-well_primary-500 text-xl font-bold">
                    <span>Ad</span>
                    <span className="font-normal">lertiser</span>
                  </Typography>
                </Link>
                <Button
                  variant="outlined"
                  size="sm"
                  className="py-1.5 ml-6 flex items-center justify-center"
                  onClick={handleBackClick}
                  placeholder={'Book a demo'}
                >
                  <KeyboardBackspace className="mr-2 text-sm" />
                  {t('header.back')}
                </Button>
              </div>
            ) : path.includes('/keywords') || path.includes('/keyword/') ? (
              <Link to="/keywords">
                <Typography className="mr-4 py-1.5 text-base font-bold text-blue-gray-900 hidden lg:block hover:text-blue-gray-900 hover:cursor-pointer">
                  Infringement Tracker
                </Typography>
              </Link>
            ) : (
              ''
            )}
            {!(
              path.includes('/settings') ||
              path.includes('account-settings') ||
              path.includes('content-marketing/') ||
              path.includes('/keywords') ||
              path.includes('/keyword/') ||
              path.includes('/admin-dashboard')
            ) && (
              <Typography
                // onClick={() => navigate('/')}
                className="mr-4 py-1.5 text-base font-bold text-blue-gray-900 hidden lg:block hover:text-blue-gray-900 hover:cursor-pointer"
              >
                {_checkLocation()}
              </Typography>
            )}
            {/* {location.pathname.includes('/transcript') ||
            location.pathname.includes('/settings') ||
            location.pathname.includes('/account-settings') ||
            location.pathname.includes('/dashboard') ? (
              <Typography
                onClick={() => navigate('/transcripts')}
                className="mr-4 py-1.5 text-base font-bold text-blue-gray-900 hidden lg:block hover:text-blue-gray-900 hover:cursor-pointer"
              >
                {_checkLocation()}
              </Typography>
            ) : location.pathname.includes('/dashboard') ? (
              <Typography
                onClick={() => navigate('/transcripts')}
                className="mr-4 py-1.5 text-base font-bold text-blue-gray-900 hidden lg:block hover:text-blue-gray-900 hover:cursor-pointer"
              >
                {t('header.socialReplies')}
              </Typography>
            ) :  */}
            {location.pathname.includes('/projects') ? (
              <Typography className="mr-4 py-1.5 text-base font-bold text-blue-gray-900 hidden lg:block hover:text-blue-gray-900 ">
                {t('header.allProjects')}
              </Typography>
            ) : location.pathname.includes('/project/') ? (
              <>
                <Typography className="mr-4 py-1.5 text-base font-bold text-blue-gray-900 hidden lg:block hover:text-blue-gray-900 ">
                  {t('header.project')}: {currentProject.name}
                </Typography>
                <div className="flex">
                  <div className="flex items-center -space-x-4">
                    {filteredMembers.map((member) => {
                      if (member.profilePicture) {
                        return (
                          <CustomTooltip
                            key={member.id}
                            placement="bottom"
                            content={
                              <div className="items-center justify-center flex flex-col">
                                <Typography
                                  color="white"
                                  className="font-medium"
                                >
                                  {member.name}
                                </Typography>
                                <Typography
                                  variant="small"
                                  color="white"
                                  className="font-normal opacity-80"
                                >
                                  {member.email}
                                </Typography>
                              </div>
                            }
                            className="bg-lstnBlueGray-400"
                          >
                            <Avatar
                              key={member.id}
                              placeholder={undefined}
                              size="sm"
                              variant="circular"
                              alt={member.name}
                              className="ring-2 ring-white hover:z-10 focus:z-10"
                              src={member.profilePicture}
                            />
                          </CustomTooltip>
                        )
                      } else {
                        return (
                          <CustomTooltip
                            key={member.id}
                            placement="bottom"
                            content={
                              <div className="items-center justify-center flex flex-col">
                                <Typography
                                  color="white"
                                  className="font-medium"
                                >
                                  {member.name}
                                </Typography>
                                <Typography
                                  variant="small"
                                  color="white"
                                  className="font-normal opacity-80"
                                >
                                  {member.email}
                                </Typography>
                              </div>
                            }
                            className="bg-lstnBlueGray-400"
                          >
                            <div
                              key={member.id}
                              className="ring-2 ring-white hover:z-10 focus:z-10 rounded-full"
                            >
                              <UserProfileAvatar
                                key={member.id}
                                userName={member.name!}
                                avatarUrl={member.profilePicture}
                                width="36px"
                                height="36px"
                              />
                            </div>
                          </CustomTooltip>
                        )
                      }
                    })}
                  </div>
                  {
                    <Typography
                      className="text-sm font-medium text-lstnBlue-500 cursor-pointer text-lstnBlueGray-900 flex items-center justify-start"
                      onClick={(event) => {
                        event.stopPropagation()
                        handleOpenAdd()
                      }}
                    >
                      <div className="inline-block justify-center ml-5 bg-lstnBlueGray-500 border-lstnBlueGray-500 text-white w-3 h-3 p-0 rounded-full text-center relative mr-1">
                        <svg width="12" height="12" aria-hidden="true">
                          <use xlinkHref="#icon-pluss" />
                        </svg>
                      </div>
                      {t('header.add')}
                    </Typography>
                  }
                </div>
              </>
            ) : null}
          </div>
          {location.pathname.includes('/transcript') ||
          location.pathname.includes('/settings') ||
          location.pathname.includes('/project') ||
          location.pathname.includes('/dashboard') ||
          location.pathname.includes('/content-marketing') ||
          location.pathname.includes('/directories-listing') ||
          location.pathname.includes('/integrations') ||
          location.pathname.includes('/account-settings') ||
          location.pathname.includes('/keywords') ||
          location.pathname.includes('/keyword/') ||
          location.pathname.includes('/admin-dashboard') ? (
            <div className="space-x-6 flex items-center">
              {/* {transcript && Object.keys(transcript).length > 0 && ( */}
              {/* // && userStatus.plan.product ===
              process.env.REACT_APP_FREE_PLAN_ID */}
              {userStatus &&
              currentUserPlan?.stripe_metadata_isFreePlan === 'true' ? (
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={() => {
                    if (keywords.length > 0) {
                      navigate(`/keyword/${keywords[0].id}/edit`)
                    } else {
                      navigate('/keywords')
                      childRef?.current!.handleKeywordOpen()
                    }
                  }}
                  className="text-lstnGreen-500 border-lstnGreen-500"
                  placeholder={'upgrade'}
                >
                  {t('modal.upgrade')}
                </Button>
              ) : (
                ''
              )}
              <Button
                variant="outlined"
                size="sm"
                className="hidden lg:block"
                onClick={handleOpen}
                placeholder={'Book a demo'}
              >
                {t('bookCall')}
              </Button>
              {/* )} */}
              <div>
                <Auth isEnterprise={isEnterprise} />
              </div>
              {/* {transcript && Object.keys(transcript).length > 0 && <Avatar size="sm" variant="circular" src="/Avatar.png" alt="tania " />} */}
              <MenuDefault />
            </div>
          ) : (
            ''
          )}
        </div>
      </Navbar>
      <DialogComponent open={open} handleClose={handleOpen} />
      <AddDialog
        open={openAddDialog}
        handleOpen={handleOpenAdd}
        project={currentProject}
        members={unFilteredMembers}
        joinedMembers={joinedMembers}
        addMember={handleAddMember}
        accountRole={userAccount?.role!}
        source="header"
        userId={userData.id!}
      />
    </>
  )
}

export default Header
