import React, { useCallback, useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Button,
  Typography,
  Chip,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
import { RootState, useAppDispatch, useAppSelector } from '../../store/store'
import Toast from '../../components/Toast/Toast'
import { useNavigate } from 'react-router-dom'
import {
  archiveKeyword,
  fetchKeywordSubCollections,
  getKeywordSubData,
  getKeywordData,
  Iincident,
  IKeyword,
} from '../../store/features/keywordSlice'
import { Timestamp } from 'firebase/firestore'
import ArchiveConfirmDialog from './ArchiveConfirmDialog'
import { accountRole } from '../../enums'
import { getCode } from 'country-list'
import { customEvent } from '../../utils/customHooks'
// import { customEvent } from '../../utils/customHooks'

// interface IProps {
//   project: IProject
//   handleEdit: (id: string, name: string, description?: string) => void
//   handleArchive: (id: string) => void
//   roles: { [key: string]: string[] }
// }

interface Region {
  isFullySelected: boolean
  countries: string[]
}

export default function ProjectCard({
  keyword,
}: {
  keyword: IKeyword
}): React.JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [incident, setIncident] = useState<Iincident[] | undefined>()
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)

  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject } = useAppSelector((store) => store.project)
  const { user } = useAppSelector((store: RootState) => store.user)
  const { keywordroles } = useAppSelector((store) => store.keyword)

  useEffect(() => {
    const fetchData = async () => {
      const getKeywordSubCollections = await getKeywordSubData(keyword.id!)
      const getKeywordDetails = await getKeywordData(keyword.id!)
      const currentKeywordCountries: Record<string, Region> =
        getKeywordDetails?.countriesToMonitor
      const allCountries = Object.values(currentKeywordCountries).reduce(
        (acc: string[], region: Region) => {
          return acc.concat(region?.countries)
        },
        [],
      )
      if (getKeywordSubCollections && allCountries.includes('all')) {
        setIncident(getKeywordSubCollections)
      } else {
        const filteredIncidents = getKeywordSubCollections.filter(
          (incident) => {
            if (!incident?.ad_countries) return false
            return incident?.ad_countries.some((country: string) => {
              const countryCode = getCode(country.trim())
              const checkCountry = allCountries.includes(countryCode!)
              return checkCountry
            })
          },
        )
        setIncident(filteredIncidents)
      }
    }

    fetchData() // Call the async function
  }, [keyword])

  useEffect(() => {
    if (keyword.id) {
      fetchKeywordSubCollections(keyword.id!, dispatch)
    }
  }, [keyword])

  const handleOpenArchive = (): void => {
    setOpenArchiveDialog(!openArchiveDialog)
  }

  const handleCopy = (id: string): void => {
    const domain = window.location.origin
    navigator.clipboard.writeText(`${domain}/keyword/${id}`)
    Toast({ text: t && t('transcripts.copyConfirm'), variant: 'success' })
  }

  const handleArchive = () => {
    if (keyword.id) {
      archiveKeyword(keyword.id)
      customEvent('keyword_archived', {
        accountId: currentAccount.id,
        user_userId: user.uid,
        keywordId: keyword?.id!,
        projectId: currentProject?.id,
      })
    }
  }

  const currentAccountRole = currentAccount.users[user.uid!]
  const checkRole = useCallback(
    (role: string) => {
      if (keywordroles) {
        const accRoles = keywordroles[role]?.['keyword']
        return accRoles
      }
    },
    [keywordroles],
  )

  return (
    <>
      <Card
        placeholder={undefined}
        className="mt-7 w-[30rem] border h-64 hover:shadow-lg"
        onClick={() => {
          navigate('/keyword/' + keyword.id)
        }}
      >
        <CardBody placeholder={undefined} className="h-44">
          <div className="flex justify-between items-center">
            <Typography
              placeholder="Title"
              className="text-2xl text-blue-gray-900 font-medium"
            >
              {keyword.title}
            </Typography>
            <Menu placement="bottom-end" allowHover={true}>
              <MenuHandler>
                <Button
                  placeholder={undefined}
                  variant="text"
                  className="p-2 min-w-0 h-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-more-vertical"
                  >
                    <circle cx="12" cy="11" r="1"></circle>
                    <circle cx="12" cy="6" r="1"></circle>
                    <circle cx="12" cy="16" r="1"></circle>
                  </svg>
                </Button>
              </MenuHandler>
              <MenuList placeholder={undefined}>
                {checkRole(currentAccountRole?.role)?.includes(
                  accountRole.EDIT_KEYWORD,
                ) ? (
                  <MenuItem
                    placeholder={undefined}
                    onClick={(event) => {
                      event.stopPropagation()
                      navigate('/keyword/' + keyword.id + '/edit')
                    }}
                  >
                    <Typography
                      placeholder="Edit"
                      className="text-lstnBlueGray-500 font-medium text-sm"
                    >
                      {t('projects.edit')}
                    </Typography>
                  </MenuItem>
                ) : null}

                {checkRole(currentAccountRole?.role)?.includes(
                  accountRole.ARCHIVE_KEYWORD,
                ) ? (
                  <MenuItem
                    placeholder={undefined}
                    onClick={(event) => {
                      event.stopPropagation()
                      // setConfirmDelete(!conformDelete)
                      handleOpenArchive()
                      customEvent('keyword_archive_dialog_opened', {
                        accountId: currentAccount.id,
                        user_userId: user.uid,
                        keywordId: keyword?.id!,
                        projectId: currentProject?.id,
                      })
                    }}
                  >
                    <Typography
                      placeholder="Archive"
                      className="text-lstnBlueGray-500 font-medium text-sm"
                    >
                      {t('projects.archive')}
                    </Typography>
                  </MenuItem>
                ) : null}

                <MenuItem
                  placeholder={undefined}
                  onClick={(event) => {
                    event.stopPropagation()
                    handleCopy(keyword?.id!)
                  }}
                >
                  <Typography
                    placeholder="CopyLink"
                    className="text-lstnBlueGray-500 font-medium text-sm"
                  >
                    {t('projects.copyLink')}
                  </Typography>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div className="flex gap-2 items-center mt-3 mb-3">
            {/* {project.status === 'active' ? ( */}
            <Chip
              value={t('projects.active')}
              variant="outlined"
              size="sm"
              className="text-xs text-blue-gray-900"
            />
            {/* // ) : project.status === 'archived' ? ( */}
            {/* <Chip
                value={t('projects.archived')}
                variant="outlined"
                size="sm"
                className="text-xs text-blue-gray-400 border-blue-gray-300"
              /> */}
            {/* ) : null} */}
          </div>
          <Typography
            placeholder="Add Description"
            className="text-base font-normal text-lstnBlueGray-500 text-start truncate-lines"
          >
            Tracked since:{' '}
            {keyword?.trackedSince
              ? new Timestamp(
                  keyword.trackedSince.seconds,
                  keyword.trackedSince.nanoseconds,
                )
                  .toDate()
                  .toLocaleDateString('en-US', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                  })
              : 'unknown'}
            <br />
            Incidents last 30 days: {incident ? incident.length : 0} incidents
          </Typography>
        </CardBody>
      </Card>
      <ArchiveConfirmDialog
        projectName={keyword.title}
        projectId={keyword.id!}
        handleArchive={handleArchive}
        handleOpenArchive={handleOpenArchive}
        openArchiveDialog={openArchiveDialog}
      />
    </>
  )
}

export const ProjectCardSkeleton = () => {
  return (
    <>
      <Card
        placeholder={undefined}
        className="mt-7 w-[30rem] border h-64 hover:shadow-lg animate-pulse"
      >
        <CardBody placeholder={undefined} className="h-44">
          <div className="flex justify-between items-center">
            <Typography
              placeholder="Title"
              className=" h-4 w-14 rounded-md bg-gray-300"
            >
              {/* {keyword.title} */}
              &nbsp;
            </Typography>
            <Menu placement="bottom-end" allowHover={true}>
              <MenuHandler>
                <Button
                  placeholder={undefined}
                  variant="text"
                  className="!py-0 min-w-0 h-auto"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-more-vertical"
                  >
                    <circle cx="12" cy="11" r="1"></circle>
                    <circle cx="12" cy="6" r="1"></circle>
                    <circle cx="12" cy="16" r="1"></circle>
                  </svg>
                </Button>
              </MenuHandler>
              <MenuList placeholder={undefined}>
                {/* {projectSettingRole?.includes('edit') && ( */}
                <MenuItem placeholder={undefined}>
                  <Typography
                    placeholder="Edit"
                    className="mb-2 h-2 w-20 rounded-full bg-gray-300"
                  >
                    &nbsp;
                  </Typography>
                </MenuItem>
                {/* )} */}
                {/* {projectSettingRole?.includes('archive') &&
                  project.status !== 'archived' && ( */}
                <MenuItem placeholder={undefined}>
                  <Typography
                    placeholder="Archive"
                    className="mb-2 h-2 w-20 rounded-full bg-gray-300"
                  >
                    &nbsp;
                  </Typography>
                </MenuItem>
                {/* )} */}
                <MenuItem placeholder={undefined}>
                  <Typography
                    placeholder="CopyLink"
                    className="mb-2 h-2 w-20 rounded-full bg-gray-300"
                  >
                    &nbsp;
                  </Typography>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
          <div className="flex gap-2 items-center mt-3 mb-3">
            {/* {project.status === 'active' ? ( */}
            <Typography
              as="div"
              variant="h1"
              className="mb-2 h-4 w-20 rounded-full bg-gray-300"
              placeholder={undefined}
            >
              &nbsp;
            </Typography>
          </div>
          <div>
            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-48 rounded-full bg-gray-300"
              placeholder={undefined}
            >
              &nbsp;
            </Typography>

            <Typography
              as="div"
              variant="paragraph"
              className="mb-2 h-2 w-48 rounded-full bg-gray-300"
              placeholder={undefined}
            >
              &nbsp;
            </Typography>
          </div>
        </CardBody>
      </Card>
    </>
  )
}
