import { Card } from '@material-tailwind/react'
import React, { useCallback, useEffect, useState } from 'react'
import Typography from '../Typography/Typography'
import firebase from 'firebase/compat/app'
import { useAppSelector } from '../../store/store'
import { differenceInDays } from 'date-fns'
import { IAdvertiser, Iincident } from '../../store/features/keywordSlice'
import { fetchAdvertiserByDomain } from '../../store/features/adSlice'
import { allStatuses } from '../../pages/IncidentDetails'

const columnMappings = [
  {
    title: 'Title',
    field: 'title',
    width: '50%',
  },
  {
    title: 'Status',
    field: 'status',
    width: '15%',
  },
  {
    title: 'Advertiser',
    field: 'advertiser',
    width: '15%',
  },
  {
    title: 'First Identified Date',
    field: 'date',
    width: '20%',
  },
]

interface Ivoilation {
  openDrawer: (id: string) => void
  search: string
  date: Date | any
  status: string
  IncidentFiltered: Iincident[]
}

interface TableProps {
  filteredIncidents: Iincident[]
  openDrawer: (id: string) => void
}

const fetchAdvertiserName = async (
  advertiserId: string,
): Promise<string | null> => {
  try {
    const advertiser = (await fetchAdvertiserByDomain(
      advertiserId,
    )) as IAdvertiser
    return advertiser ? advertiser.legal_name : 'N/A'
  } catch (error) {
    console.error('Error fetching advertiser:', error)
    return 'N/A'
  }
}

const Table: React.FC<TableProps> = ({ filteredIncidents, openDrawer }) => {
  const { currentAccount } = useAppSelector((store) => store.account)
  const [advertiserNames, setAdvertiserNames] = useState<
    Record<string, string> | any
  >({})

  const getAdvertiserName = useCallback(
    async (advertiserId: string) => {
      if (!advertiserNames[advertiserId]) {
        const name = await fetchAdvertiserName(advertiserId)
        setAdvertiserNames((prev: any) => ({ ...prev, [advertiserId]: name }))
        return name
      }
    },
    [advertiserNames],
  )

  useEffect(() => {
    filteredIncidents.forEach((incident) => {
      if (incident.advertiserWebsite) {
        getAdvertiserName(incident.advertiserWebsite)
      }
    })
  }, [filteredIncidents, getAdvertiserName])

  return (
    <>
      {filteredIncidents.map((value, index) => {
        const classes = 'p-2 text-base text-lstnBlueGray-900 font-normal'
        const createdAt = value.createdAt as firebase.firestore.Timestamp
        const formattedDate = createdAt.toDate().toLocaleString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })
        const advertiserName =
          advertiserNames[value.advertiserWebsite!] || value.ad_source || '...'
        //console.log('Advertiser name:', advertiserName)
        return (
          <tr
            key={index}
            className={`cursor-pointer hover:bg-[#F9FAFB] border-b border-blue-gray-100 p-4 ${
              currentAccount.subscriptionStatus === 'canceled' ||
              (currentAccount.subscriptionStatus === 'trialing' && index > 0)
                ? 'blur-sm'
                : ''
            }`}
            onClick={() => {
              if (
                currentAccount.subscriptionStatus !== 'trialing' ||
                index === 0
              ) {
                if (currentAccount.subscriptionStatus !== 'canceled') {
                  openDrawer(value?.docId!)
                }
              }
            }}
          >
            <td className={`${classes} pl-4`}>
              <div className="flex flex-col space-y-1">
                <Typography className="text-base text-lstnBlueGray-900 font-medium">
                  {value.ad_title}
                </Typography>
                <Typography className="text-base text-lstnBlueGray-800 font-normal">
                  {value.ad_subtitle}
                </Typography>
              </div>
            </td>
            <td className={classes}>
              <div className="flex items-center gap-2">
                {(() => {
                  const matchingStatus = allStatuses.find(
                    (status) =>
                      status.value.toLowerCase() === value.status.toLowerCase(),
                  )
                  return (
                    matchingStatus && (
                      <span
                        className={`h-6 w-6 ${matchingStatus.color} rounded-full !flex !justify-center !items-center`}
                        style={{
                          display: 'flex',
                        }}
                      >
                        {matchingStatus.icon}
                      </span>
                    )
                  )
                })()}
                <Typography className="text-base text-lstnBlueGray-800 font-normal">
                  {value.status}
                </Typography>
              </div>
            </td>
            <td className={classes}>
              <Typography className="text-base text-lstnBlueGray-800 font-normal">
                {advertiserName}
              </Typography>
            </td>
            <td className={classes}>
              <Typography className="text-base text-lstnBlueGray-800 font-normal">
                {formattedDate}
              </Typography>
            </td>
          </tr>
        )
      })}
    </>
  )
}
export const ViolationTable: React.FC<Ivoilation> = ({
  openDrawer,
  search,
  date,
  status,
  IncidentFiltered,
}) => {
  // const { currentKeywordIncidents } = useAppSelector((store) => store.keyword)
  // const { currentAccount } = useAppSelector((store) => store.account)
  const [filteredIncidents, setFilteredIncidents] = useState(IncidentFiltered)
  useEffect(() => {
    let incidents = IncidentFiltered
    if (search) {
      incidents = incidents.filter(
        (incident) =>
          incident.ad_title.toLowerCase().includes(search.toLowerCase()) ||
          incident.ad_subtitle.toLowerCase().includes(search.toLowerCase()),
      )
    }
    if (status !== '') {
      if (status !== 'all') {
        incidents = incidents.filter((incident) => incident.status === status)
      }
    }

    if (date) {
      incidents = incidents.filter((incident) => {
        const incidentDate = incident.createdAt.toDate()
        // console.log(incidentDate, 'incidentDate')
        const diff = differenceInDays(incidentDate, date)
        return diff === 0
      })
    }

    incidents.sort(
      (a, b) => b.createdAt.toDate().getTime() - a.createdAt.toDate().getTime(),
    )

    setFilteredIncidents(incidents)
  }, [search, date, IncidentFiltered, status])

  return (
    <div className="w-full min-h-[400px]">
      <Card
        className="h-full w-full mt-6 overflow-hidden"
        placeholder={undefined}
      >
        <table className="text-left border border-blue-gray-50 overflow-hidden">
          <thead>
            <tr>
              {columnMappings.map((val, index) => (
                <th
                  key={index}
                  className="border-b p-3 px-4 border-blue-gray-100 bg-[#F9FAFB] gap-2 h-10 text-sm text-blue-gray-900 font-semibold leading-none"
                  style={{
                    width: val.width,
                  }}
                >
                  <Typography
                    variant="small"
                    className="font-medium text-lstnBlueGray-900 text-base"
                  >
                    {val.title}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredIncidents.length === 0 ? (
              <tr>
                <td
                  colSpan={columnMappings.length + 1}
                  className="text-lstnBlueGray-500 text-center h-[350px]"
                >
                  <div className="w-96 mx-auto">
                    <div className="flex justify-center mb-4">
                      <img src="/noInfringementFound.svg" alt="img" />
                    </div>
                    <Typography className="text-base font-semibold text-lstnBlueGray-900 text-center mb-3">
                      No Infringement Found
                    </Typography>
                    <Typography
                      variant="small"
                      className="text-base font-normal"
                    >
                      We are monitoring your keyword. Any infringement will show
                      up here.
                    </Typography>
                  </div>
                </td>
              </tr>
            ) : (
              <Table
                filteredIncidents={filteredIncidents}
                openDrawer={openDrawer}
              />
            )}
          </tbody>
        </table>
      </Card>
    </div>
  )
}
