import React, { useCallback, useMemo, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  //   Checkbox,
} from '@material-tailwind/react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import SearchIcon from '@material-ui/icons/Search'
import Checkbox from '../Checkbox/Checkbox'
import { useAppSelector } from '../../store/store'
import { useParams } from 'react-router-dom'
import { customEvent } from '../../utils/customHooks'

type Selection = {
  [region: string]: {
    isFullySelected: boolean
    countries: string[]
  }
}
interface CountriesMonitorMultiSelectProps {
  setSelected: React.Dispatch<React.SetStateAction<Selection>>
  selected: Selection
  setTotalPrices: (val: number) => void
  pricesIds: string[]
  setPricesIds: (val: string[]) => void
  isEditing: boolean
  selectedCountries: Selection
}
const CountriesMonitorMultiSelect: React.FC<
  CountriesMonitorMultiSelectProps
> = ({
  setSelected,
  selected,
  setTotalPrices,
  pricesIds,
  setPricesIds,
  isEditing,
  selectedCountries,
}) => {
  const [expanded, setExpanded] = React.useState<string[]>([])
  const [searchTerm, setSearchTerm] = React.useState('')
  // const [isWorldwideSelected, setIsWorldwideSelected] = React.useState(false)
  // const [selected, setSelected] = React.useState<Selection>({})

  const { sortedRegions } = useAppSelector((store) => store.plan)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { currentProject } = useAppSelector((store) => store.project)
  const { user } = useAppSelector((store) => store.user)

  const params = useParams()

  const regions = useMemo(() => {
    return [...sortedRegions]
  }, [sortedRegions])
  // console.log(regions)

  const toggleExpand = useCallback((region: string) => {
    setExpanded((prev) =>
      prev.includes(region)
        ? prev.filter((r) => r !== region)
        : [...prev, region],
    )
  }, [])

  const toggleRegion = (region: string) => {
    setSelected((prev: Selection) => {
      const newSelection = { ...prev }
      const regionData = regions.find((r) => r.name === region)
      if (region === 'Worldwide') {
        if (newSelection['Worldwide']?.isFullySelected) {
          return {}
        } else {
          return regions.reduce((acc, r) => {
            if (r.name !== 'Worldwide') {
              acc[r.name!] = {
                isFullySelected: true,
                countries: r?.countries
                  .map(
                    (c: { stripe_metadata_countryCode: string }) =>
                      c?.stripe_metadata_countryCode,
                  )
                  .filter(
                    (code: string | undefined): code is string =>
                      code !== undefined,
                  ),
              }
            } else {
              acc[r.name!] = {
                isFullySelected: true,
                countries: ['all'],
              }
            }
            return acc
          }, {} as Selection)
        }
      }
      if (newSelection[region]?.isFullySelected) {
        delete newSelection[region]
      } else {
        newSelection[region] = {
          isFullySelected: true,
          countries:
            regionData?.countries.map(
              (c: { stripe_metadata_countryCode: string }) =>
                c.stripe_metadata_countryCode!,
            ) || [],
        }
      }

      return newSelection
    })
  }

  const toggleCountry = useCallback(
    (region: string, countryCode: string) => {
      setSelected((prev) => {
        // Create a shallow copy of the previous selection state
        const newSelection: Selection = { ...prev }

        // Ensure the region exists
        if (!newSelection[region]) {
          newSelection[region] = { isFullySelected: false, countries: [] }
        }

        // Create a copy of the region data to avoid direct mutation
        const regionData = { ...newSelection[region] }

        if (regionData.countries.includes(countryCode)) {
          regionData.countries = regionData.countries.filter(
            (c) => c !== countryCode,
          )
          customEvent('keyword_countries_de_selected', {
            accountId: currentAccount.id,
            user_userId: user.uid,
            keywordId: params.keywordId,
            actionMetadata: countryCode,
            projectId: currentProject?.id,
          })
          regionData.isFullySelected = false
        } else {
          customEvent('keyword_countries_selected', {
            accountId: currentAccount.id,
            user_userId: user.uid,
            keywordId: params.keywordId,
            actionMetadata: countryCode,
            projectId: currentProject?.id,
          })
          regionData.countries = [...regionData.countries, countryCode]
        }

        // Get the region data from the regions array to check the full selection status
        const regionDetails = regions.find((r) => r.name === region)
        regionData.isFullySelected =
          regionData.countries.length === regionDetails?.countries.length

        // If the countries array is empty, delete the region
        if (regionData.countries.length === 0) {
          delete newSelection[region]
        } else {
          // Update the region with the modified data
          newSelection[region] = regionData
        }

        return newSelection
      })
    },
    [regions],
  )

  const isRegionSelected = (region: string) => {
    return selected[region]?.isFullySelected || false
  }

  useEffect(() => {
    if (selected) {
      const allSelected = regions.every((region) => {
        if (region.name === 'Worldwide') return true

        return selected[region.name!]?.isFullySelected === true
      })
      if (!allSelected && selected['Worldwide']?.isFullySelected === true) {
        setSelected((prev: Selection) => {
          const newSelection = { ...prev }
          delete newSelection['Worldwide']
          return newSelection
        })
      }
      if (
        (allSelected && selected['Worldwide']?.isFullySelected === false) ||
        (allSelected && !selected['Worldwide'])
      ) {
        setSelected((prev: Selection) => {
          const newSelection = { ...prev }
          console.log(newSelection, 'newSelection')
          newSelection['Worldwide'] = {
            isFullySelected: true,
            countries: ['all'],
          }
          return newSelection
        })
      }
    }
  }, [selected, regions])

  const isCountrySelected = (region: string, countryCode: string) => {
    return selected[region]?.countries.includes(countryCode) || false
  }

  const filteredRegions =
    regions &&
    regions?.filter(
      (region) =>
        region.name!.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        region.countries.some(
          (country: { name: string }) =>
            country.name?.toLowerCase().includes(searchTerm?.toLowerCase()),
        ),
    )
  const calculateTotalPrize = useCallback(
    ({ selected }: { selected: Selection }) => {
      let total = 0
      let ids: string[] = []
      if (
        selected &&
        selected.Worldwide &&
        selected.Worldwide.isFullySelected
      ) {
        const region = regions.find((r) => r.name === 'Worldwide')
        if (region && region.prices) {
          total = region.prices[0].unit_amount! / 100
          ids.push(region.prices[0].id)
        }
      } else {
        Object.entries(selected).forEach(
          ([regionName, { isFullySelected, countries }]) => {
            const region = regions.find((r) => r.name === regionName)
            if (isFullySelected) {
              if (region && region.prices) {
                total += region.prices[0].unit_amount! / 100 || 0
                if (region && region.prices) {
                  ids.push(region.prices[0].id)
                }
              }
            } else {
              countries.forEach((code) => {
                const countryPrize =
                  region?.countries.find(
                    (c: { stripe_metadata_countryCode: string }) =>
                      c.stripe_metadata_countryCode === code,
                  )?.prices[0].unit_amount! / 100 || 0
                total += countryPrize
                const country = region?.countries.find(
                  (c: { stripe_metadata_countryCode: string }) =>
                    c.stripe_metadata_countryCode === code,
                )
                if (country && country.prices) {
                  ids.push(country.prices[0].id)
                }
              })
            }
          },
        )
      }
      return { total, ids }
    },
    [regions, selected],
  )

  useEffect(() => {
    const { total, ids } = calculateTotalPrize({ selected })
    setTotalPrices(total)
    setPricesIds(ids)
  }, [calculateTotalPrize, selected, regions])

  const getActivePlanIds = useMemo(() => {
    if (currentAccount.stripeSubscriptionData && params.keywordId) {
      const items =
        currentAccount.stripeSubscriptionData[params.keywordId!]?.items?.data
      return items?.map((item: { price: { id: string } }) => item?.price?.id)
    }
  }, [currentAccount, currentAccount.stripeSubscriptionData, params])

  const activePlan = getActivePlanIds
  const removedPlanIds = getActivePlanIds?.filter(
    (item: string) => !pricesIds.includes(item),
  )
  const uniqueValues = pricesIds.filter((item) => !activePlan?.includes(item))

  return (
    <div className="w-full max-w-md p-4 bg-white rounded-lg shadow">
      <h2 className="text-lg font-semibold mb-4">
        Select Regions and Countries
      </h2>
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Search regions or countries..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
        />
        <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
      </div>
      {isEditing ? (
        <div>
          <div className="mt-4 text-base text-blue-gray-400 font-medium flex items-center justify-between">
            <div>Current Price:</div>
            <div className="text-blue-gray-900">
              ${calculateTotalPrize({ selected: selectedCountries })?.total}
              &nbsp;/ month
            </div>
          </div>
          <div className="mt-2 text-base font-medium text-blue-gray-400 flex items-center justify-between">
            <div>New Price:</div>
            <div className="text-blue-gray-900">
              ${calculateTotalPrize({ selected })?.total}&nbsp;/ month
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-4 text-lg font-bold text-blue-gray-400 flex items-center justify-between">
          <div>Total Price:</div>
          <div className="text-blue-gray-900">
            ${calculateTotalPrize({ selected })?.total}&nbsp;/ month
          </div>
        </div>
      )}
      <hr className="py-2" />

      <div className="space-y-2 max-h-[40vh] overflow-y-auto">
        {filteredRegions &&
          filteredRegions.length > 0 &&
          filteredRegions.map((region) => (
            <div
              key={region.name}
              //   className="border rounded"
            >
              <div className="flex items-center p-2">
                {region.name !== 'Worldwide' && (
                  <ArrowForwardIosIcon
                    fontSize="small"
                    className={`
                      !w-4 !h-4 mr-2 transition-transform cursor-pointer
                      ${
                        expanded.includes(region.name!)
                          ? 'transform rotate-90'
                          : ''
                      }
                  `}
                    onClick={() => toggleExpand(region.name!)}
                  />
                )}
                <div className="countryCheckbox flex items-center">
                  <Checkbox
                    id={region.name}
                    checked={isRegionSelected(region.name!)}
                    onChange={() => toggleRegion(region.name!)}
                    className="!flex items-center !justify-center"
                  />
                </div>

                <label
                  htmlFor={region.name}
                  className="text-sm font-medium text-blue-gray-400 flex-grow"
                >
                  {region.name}
                </label>
                <div className="text-sm font-semibold">
                  {region.stripe_metadata_originalPrice && (
                    <span className="line-through text-muted-foreground mr-2 text-blue-gray-200">
                      ${region.stripe_metadata_originalPrice}
                    </span>
                  )}
                  {region && region.prices && (
                    <span className="text-blue-gray-400">
                      ${region.prices[0].unit_amount! / 100}
                    </span>
                  )}
                </div>
              </div>
              {region.name !== 'Worldwide' &&
                expanded.includes(region.name!) && (
                  <div className="pl-14 pr-2 pb-2 space-y-1">
                    {region.countries.map(
                      (country: {
                        stripe_metadata_countryCode: string
                        name: string
                        prices: { unit_amount: number; id: string }[]
                      }) => (
                        <div
                          key={country.stripe_metadata_countryCode}
                          className="flex items-center p-0"
                        >
                          <div className="countryCheckbox flex items-center">
                            <Checkbox
                              id={`${region.name}-${country.stripe_metadata_countryCode}`}
                              checked={isCountrySelected(
                                region.name!,
                                country.stripe_metadata_countryCode!,
                              )}
                              onChange={() =>
                                toggleCountry(
                                  region.name!,
                                  country.stripe_metadata_countryCode!,
                                )
                              }
                            />
                          </div>

                          <label
                            htmlFor={`${region.name}-${country.stripe_metadata_countryCode}`}
                            className="text-sm flex-grow text-blue-gray-400"
                          >
                            {country.name}
                          </label>
                          <span className="text-sm font-semibold text-blue-gray-400">
                            ${country.prices[0].unit_amount! / 100}
                          </span>
                        </div>
                      ),
                    )}
                  </div>
                )}
            </div>
          ))}
      </div>

      {isEditing ? (
        activePlan &&
        removedPlanIds &&
        activePlan.length == removedPlanIds.length ? (
          <div className="mt-2 text-sm font-normal text-blue-gray-700 ">
            <span className="font-bold">Warning: </span> this will stop tracking
            this keyword! Proceed with caution. Tracking will be stopped now.
            You will not longer be billed at the next renewal date.
          </div>
        ) : uniqueValues?.length > 0 && removedPlanIds?.length == 0 ? (
          <div className="mt-2 text-sm font-normal text-blue-gray-700 ">
            Your new monthly bill will be $
            {calculateTotalPrize({ selected })?.total}. We will charge
            immediately the pro-rated amount, and at renewal date the full
            amount.
          </div>
        ) : removedPlanIds?.length > 0 ? (
          <div className="mt-2 text-sm font-normal text-blue-gray-700 ">
            <span className="font-bold">Warning: </span> This will stop tracking
            this keyword in some countries! Proceed with caution. Tracking will
            be stopped now. You will not longer be billed for those countries at
            the next renewal date.
          </div>
        ) : (
          ''
        )
      ) : (
        ''
      )}
    </div>
  )
}

export function DialogWithCountriesMonitorSelector({
  open,
  handleOpen,
  selected,
  setSelected,
  setTotalPrices,
  pricesIds,
  setPricesIds,
  // setSelectedSave,
  isEditing,
  handleSubmit,
}: {
  open: boolean
  handleOpen: () => void
  selected: Selection
  setSelected: React.Dispatch<React.SetStateAction<Selection>>
  setTotalPrices: (val: number) => void
  pricesIds: string[]
  setPricesIds: (val: string[]) => void
  // setSelectedSave: (val: boolean) => void
  isEditing: boolean
  handleSubmit?: () => void
}) {
  const params = useParams()
  const { keywords } = useAppSelector((store) => store.keyword)
  const [selectedCountries, setSelectedCountries] = useState<Selection | {}>({})

  useEffect(() => {
    if (keywords && params.keywordId) {
      const keywordData = keywords.find(
        (keyword) => keyword.id === params.keywordId,
      )
      setSelectedCountries(keywordData?.countriesToMonitor!)
    }
  }, [params, keywords])

  const handleCancel = useCallback(() => {
    if (selectedCountries) {
      setSelected(selectedCountries)
    } else {
      setSelected({})
    }
  }, [selectedCountries])

  return (
    <>
      <Dialog
        placeholder={undefined}
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none"
      >
        <Card className="mx-auto w-full max-w-[32rem]" placeholder={undefined}>
          <CardBody className="flex flex-col gap-4" placeholder={undefined}>
            <CountriesMonitorMultiSelect
              setSelected={setSelected}
              selected={selected}
              setTotalPrices={setTotalPrices}
              pricesIds={pricesIds}
              setPricesIds={setPricesIds}
              isEditing={isEditing}
              selectedCountries={selectedCountries}
            />
          </CardBody>
          <CardFooter className="pt-0 flex justify-end" placeholder={undefined}>
            <Button
              variant="text"
              color="black"
              className="mr-1"
              placeholder={undefined}
              onClick={() => {
                handleOpen()
                handleCancel()
              }}
            >
              <span>Cancel</span>
            </Button>
            <Button
              variant="filled"
              className="!bg-lstnGreen-500"
              placeholder={undefined}
              onClick={() => {
                if (isEditing && handleSubmit) {
                  handleOpen()
                  handleSubmit()
                } else {
                  handleOpen()
                }
                // setSelectedSave(true)
              }}
            >
              <span>{isEditing ? 'EDIT COUNTRIES' : 'ADD COUNTRIES'}</span>
            </Button>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  )
}
