import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// import type { PayloadAction } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
import { IStripePrice, IStripeProduct } from '../../interfaces'
import { AppDispatch } from '../store'
// import { useMemo } from 'react'

export interface IPlanData extends IStripeProduct {
  name: string
  stripe_metadata_region?: any
  stripe_metadata_countryCode?: string
  stripe_metadata_originalPrice?: string
  planID?: string
  prices: IStripePrice[]
  stripe_metadata_allowedMinutes: string
  stripe_metadata_allowedCredits: string
  stripe_metadata_bookACall: string
  stripe_metadata_isFreePlan: string
  stripe_metadata_isVisible: string
  stripe_metadata_portalAccess: string
  stripe_metadata_socialMonitoringAllowedKeywords: string
}
interface IPlanState {
  plans: [] | IPlanData[]
  sortedRegions: [] | any
  loading: string
  error: Error | null
  product: any | null
}

const initialState: IPlanState = {
  plans: [],
  sortedRegions: [],
  loading: 'idle',
  error: null,
  product: null,
}

const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setPlans: (state, action) => {
      state.plans = action.payload
    },
    // setProductDetails: (state, action) => {
    //   state.product = action.payload
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanData.pending, (state) => {
        state.loading = 'pending'
        state.error = null
      })
      .addCase(fetchPlanData.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        state.error = null
        // console.log(action.payload, 'plans')
        const plans: [] | IPlanData[] =
          action.payload !== undefined ? action.payload : []
        // Europe
        const europe_countries = plans
          .filter((r) => r?.stripe_metadata_region === 'Europe')
          .sort((a, b) => a.name.localeCompare(b.name))
        // Americas and Carribeans
        const north_america_countries = plans.filter(
          (r) => r?.stripe_metadata_region === 'North America',
        )
        const south_america_countries = plans.filter(
          (r) => r?.stripe_metadata_region === 'South America',
        )
        const central_america_countries = plans.filter(
          (r) => r?.stripe_metadata_region === 'Central America',
        )
        const carribean_countries = plans.filter(
          (r) => r?.stripe_metadata_region === 'Carribean',
        )
        // Africa
        const africa_countries = plans
          .filter((r) => r?.stripe_metadata_region === 'Africa')
          .sort((a, b) => a.name.localeCompare(b.name))
        // Middle East
        const middle_east_countries = plans
          .filter((r) => r?.stripe_metadata_region === 'Middle East')
          .sort((a, b) => a.name.localeCompare(b.name))
        // Asia-Pacific
        const asia_countries = plans.filter(
          (r) => r?.stripe_metadata_region === 'Asia',
        )
        const oceania_countries = plans.filter(
          (r) => r?.stripe_metadata_region === 'Oceania',
        )

        const europe = plans.find((r) => r?.name === 'Europe')
        const worldwide = plans.find((r) => r?.name === 'Worldwide')
        const americas_and_carribeans = plans.find(
          (r) => r?.name === 'Americas and Carribeans',
        )
        const africa = plans.find((r) => r?.name === 'Africa')
        const middle_east = plans.find((r) => r?.name === 'Middle East')
        const asia_pacific = plans.find((r) => r?.name === 'Asia-Pacific')

        const regions = () => {
          const sortedRegions = [
            {
              countries: [],
              ...worldwide,
            },
            {
              countries: europe_countries,
              ...europe,
            },
            {
              countries: [
                ...carribean_countries,
                ...north_america_countries,
                ...south_america_countries,
                ...central_america_countries,
              ].sort((a, b) => a.name.localeCompare(b.name)),
              ...americas_and_carribeans,
            },
            {
              countries: [...africa_countries],
              ...africa,
            },
            {
              countries: [...middle_east_countries],
              ...middle_east,
            },
            {
              countries: [...asia_countries, ...oceania_countries].sort(
                (a, b) => a.name.localeCompare(b.name),
              ),
              ...asia_pacific,
            },
          ]
          return [
            sortedRegions[0],
            ...sortedRegions
              .slice(1)
              .sort((a, b) => a?.name!.localeCompare(b?.name!)),
          ]
        }
        const sortedRegions = regions()
        state.sortedRegions = sortedRegions
      })
      .addCase(fetchPlanData.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = new Error(action.error.message)
      })
      .addCase(fetchProductById.pending, (state) => {
        state.loading = 'pending'
      })
      .addCase(fetchProductById.fulfilled, (state, action) => {
        state.loading = 'succeeded'
        state.product = action.payload
      })
      .addCase(fetchProductById.rejected, (state, action) => {
        state.loading = 'failed'
        state.error = new Error(action.error.message)
      })
  },
})

export const { setPlans } = planSlice.actions
export default planSlice.reducer

const fetchPlanData = createAsyncThunk(
  'plans/fetchData',
  async (data: IPlanData[], { dispatch }) => {
    try {
      dispatch(setPlans(data))
      return data
    } catch (error) {
      console.log(error)
    }
  },
)

export const helperPlanData = ({ dispatch }: { dispatch: AppDispatch }) => {
  const stripe_productsQuery = database.collection('stripe_products')
  const unsubscribe = stripe_productsQuery.onSnapshot(
    (stripe_productsQuerySnapShot) => {
      if (!stripe_productsQuerySnapShot.empty) {
        const allstripe_products = stripe_productsQuerySnapShot.docs.map(
          async (doc) => {
            const stripeData = doc.data()
            const userPriceQuery = stripe_productsQuery
              .doc(doc.id)
              .collection('prices') // Replace 'YOUR_DOCUMENT_ID' with the actual document ID
            const pricesSnapShot = await userPriceQuery.get()
            const pricesData = pricesSnapShot.docs.map((priceDoc) => ({
              id: priceDoc.id,
              ...priceDoc.data(),
            }))

            // Merge the stripeData with pricesData
            const mergedstripeData = {
              ...stripeData,
              planID: doc.id,
              prices: pricesData,
            }
            return mergedstripeData
          },
        )

        // If you want to log all merged data for the entire collection
        Promise.all(allstripe_products).then((mergedDataArray) => {
          dispatch(fetchPlanData(mergedDataArray as IPlanData[]))
        })
      }
    },
  )
  return () => unsubscribe()
}

export const fetchProductById = createAsyncThunk(
  'plans/fetchProductById',
  async (productIds: string[]) => {
    try {
      const productsData: Record<string, any> = {}

      for (const productId of productIds) {
        const productDocRef = database
          .collection('stripe_products')
          .doc(productId)
        const productDoc = await productDocRef.get()

        if (productDoc.exists) {
          productsData[productId] = productDoc.data()
        } else {
          console.warn(`Product with ID ${productId} not found.`)
        }
      }
      return productsData
    } catch (error) {
      console.error('Error fetching product data:', error)
      throw error
    }
  },
)
