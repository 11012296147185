import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Typography,
  Input,
  Button,
  Radio,
  Dialog,
  DialogBody,
} from '@material-tailwind/react'
import React, {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Toast from '../components/Toast/Toast'
import firebase from 'firebase/compat/app'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
// import ImagePlaceholderSkeleton from '../components/ImagePlaceholderSkeleton'
// import UserProfileAvatar from '../components/UserProfileAvatar'
import { useAppSelector } from '../store/store'
import AvatarLogo from '../icons/AccountLogo.svg'
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import { database, storage } from '../firebaseApp'
import AdInfringementWithImage from '../components/AdminDashboard'
import InfringementWithImageDetail from '../components/AdminDashboard/infringementWithImageDetail'
import Selector from '../components/SelectInput/Select'
import SearchIcon from '@material-ui/icons/Search'
import { Close } from '@material-ui/icons'
import moment from 'moment'

interface AdIncidentInfo {
  adlink: string
  description: string
  displayedLink: string
  signedURL: string
  source: string
  title: string
  trackingLink: string
  websiteURL: string
}

const AdminPanel = () => {
  const [activeTab, setActiveTab] = React.useState('add-Advertiser')
  const [loading, setLoading] = React.useState(false)
  const [advertiserId, setAdvertiserId] = React.useState<string>('')
  const [advertiserName, setAdvertiserName] = React.useState('')

  const [country, setCountry] = React.useState('')
  const [keywordId, setKeywordId] = React.useState('')
  const [incidentId, setIncidentId] = React.useState('')
  const [imageURL, setImageURL] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState('')

  const [adTrackingUrl, setAdTrackingUrl] = useState<string>('')
  const [adDomainDisplayed, setAdDomainDisplayed] = useState<string>('')

  const [btnDisabled, setBtnDisabled] = useState({
    country: true,
    keywordId: true,
    incidentId: true,
  })

  const [operation, setOperation] = useState('image-only')
  const { sortedRegions } = useAppSelector((store) => store.plan)

  const countries = useMemo(() => {
    return sortedRegions
      .map((sortedRegion: any) => {
        if (sortedRegion.countries.length > 0) {
          return sortedRegion.countries.map((country: any) => {
            return {
              value: country.stripe_metadata_countryCode,
              label: country.name,
            }
          })
        } else {
          return []
        }
      })
      .flat()
      .sort((a: { label: string }, b: { label: string }) =>
        a.label.localeCompare(b.label),
      )
  }, [sortedRegions])
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [findInfringement, setFindInfringement] = useState({
    Keyword: '',
    findInfringementCountry: '',
    incidents: [],
    errorMessageNotFound: '',
    errorRequestId: '',
    loading: false,
  })
  const openModal = (url: string) => setSelectedImage(url)
  const closeModal = () => setSelectedImage(null)

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> }
  }) => {
    setOperation(event.target.value)
  }

  useEffect(() => {
    setBtnDisabled({
      country: country.length < 2,
      keywordId: keywordId.length < 2,
      incidentId: incidentId.length < 2,
    })
  }, [country, keywordId, incidentId])

  // const { currentAccount } = useAppSelector((store) => store.account)
  const { userData } = useAppSelector((store) => store.user)
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  const handleSubmit = async () => {
    if (advertiserId !== '' && advertiserName !== '') {
      setLoading(true)
      const token = await firebase?.auth()?.currentUser?.getIdToken()
      await axios
        .post(
          `${process.env.REACT_APP_FIREBASE_API}/addNewAdvertiser`,
          {
            data: {
              advertiserId: advertiserId,
              advertiserName: advertiserName,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Access-Control-Expose-Headers': 'Content-Disposition',
            },
          },
        )
        .then(() => {
          setAdvertiserId('')
          setAdvertiserName('')
          Toast({
            title: 'Advertiser Created!',
            variant: 'success',
            options: { autoClose: 5000 },
          })
          setLoading(false)
        })
        .catch((error: any) => {
          setAdvertiserId('')
          setAdvertiserName('')
          console.log(error)
          Toast({
            title: 'Creation Failed!',
            variant: 'error',
            options: { autoClose: 5000 },
          })
          setLoading(false)
        })
    }
  }

  const handlePictureChange = async (event: {
    target: { files: File[] }
  }): Promise<void> => {
    setIsLoading(true)
    try {
      const file = event.target.files[0]
      const name = `${file.name}_${Date.now()}`
      const storageRef = ref(storage, `${keywordId}/${name}`)
      const metadata = {
        contentType: 'image/jpeg',
      }
      await uploadBytes(storageRef, file, metadata)
      const url = await getDownloadURL(storageRef)
      setImageURL(url)
      // console.log(url)
    } catch (error) {
      console.error('Error uploading file:', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUploadButtonClick = (): void => {
    if (keywordId === '') {
      setErrorMessage('Please add a keyword ID first')
      return
    } else {
      setErrorMessage('')
    }
    document.getElementById('contained-button-file')?.click()
  }

  const updateInfringementDetails = async () => {
    setLoading(true)
    const token = await firebase?.auth()?.currentUser?.getIdToken()
    await axios
      .post(
        `${process.env.REACT_APP_FIREBASE_API}/addInfringementDetails`,
        {
          data: {
            operation: 'image-only',
            keywordId: keywordId,
            country: country,
            imageURL: imageURL,
            incidentId: incidentId,
            adTrackingUrl: adTrackingUrl,
            ad_domain_displayed: adDomainDisplayed,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
        },
      )
      .then(() => {
        setKeywordId('')
        setCountry('')
        setIncidentId('')
        setImageURL('')
        setAdTrackingUrl('')
        setAdDomainDisplayed('')
        Toast({
          title: 'Document updated successfully!',
          variant: 'success',
          options: { autoClose: 5000 },
        })
        setLoading(false)
      })
      .catch((error: any) => {
        console.log(error, 'error')
        Toast({
          title: 'Creation Failed!',
          variant: 'error',
          options: { autoClose: 5000 },
        })
        setLoading(false)
      })
  }

  const updateSelectedCountry = useCallback(
    (value: string | undefined): void => {
      const selectedCountry = value
      setFindInfringement((prevDetails) => ({
        ...prevDetails,
        findInfringementCountry: selectedCountry!,
      }))
    },
    [findInfringement],
  )

  const fetchIncidents = async ({
    keyword,
    country,
  }: {
    keyword: string
    country: string
  }) => {
    const allQuery = database
      .collection('potential_customers')
      .where('keyword', '==', keyword)
      .where('countryCode', '==', country)

    allQuery.onSnapshot((querySnapshot) => {
      if (!querySnapshot.empty) {
        const data = querySnapshot.docs
          .map((doc) => doc.data().infringements)
          .flat() as AdIncidentInfo[]
        // console.log(data, 'data')
        if (data && data.length > 0) {
          setFindInfringement((prevDetails) => ({
            ...prevDetails,
            incidents: data as any,
            // loading: false,
          }))
        }
      } else {
        console.log('No documents found')
        // setFindInfringement((prevDetails) => ({
        //   ...prevDetails,
        //   loading: false,
        //   errorMessageNotFound: 'No Infringement found for the given keyword',
        // }))
      }
    })
  }

  const handleFormSubmit = async (event: any) => {
    event.preventDefault()
    try {
      setFindInfringement((prevDetails) => ({
        ...prevDetails,
        loading: true,
        errorMessageNotFound: '',
        errorRequestId: '',
        incidents: [],
      }))

      await fetchIncidents({
        keyword: findInfringement.Keyword.toLowerCase(),
        country: findInfringement.findInfringementCountry,
      })
      const response = await axios.post(
        `${process.env.REACT_APP_FIREBASE_API}/keyword/fetchInfringementByCountry`,
        {
          keyword: findInfringement.Keyword,
          countryCode: findInfringement.findInfringementCountry,
        },
      )
      const data = response.data
      // console.log(data)
      // if (data && data.incidents && data.incidents.length > 0) {
      //   setFindInfringement((prevDetails) => ({
      //     ...prevDetails,
      //     incidents: data.incidents,
      //     loading: false,
      //   }))
      // } else
      if (data && data?.message) {
        setFindInfringement((prevDetails) => ({
          ...prevDetails,
          loading: false,
          errorMessageNotFound: data.message,
          errorRequestId: data.requestId,
        }))
      } else {
        setFindInfringement((prevDetails) => ({
          ...prevDetails,
          loading: false,
        }))
      }
      console.log('end time', moment().format('HH:mm:ss'))
    } catch (error) {
      setFindInfringement((prevDetails) => ({
        ...prevDetails,
        loading: false,
      }))
      console.log(error)
    }
  }

  const data = [
    {
      label: 'Add New Advertiser',
      value: 'add-Advertiser',
      desc: () => {
        return (
          <div className="flex flex-row flex-wrap xl:justify-between mb-6">
            <div className="w-96 space-y-4 my-4">
              <Typography
                placeholder={undefined}
                className="text-2xl font-semibold text-wellBlueGray-900"
              >
                Add New Advertiser
              </Typography>
              <div className="mb-1 flex flex-col">
                <Typography
                  placeholder={undefined}
                  variant="h6"
                  className="mb-1 text-sm font-medium text-wellBlueGray-900"
                >
                  Advertiser ID
                </Typography>
                <Input
                  autoFocus
                  type="text"
                  size="lg"
                  value={advertiserId}
                  placeholder="e.g., 1234567890"
                  className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                  labelProps={{
                    className: 'hidden',
                  }}
                  style={{ borderRadius: '8px' }}
                  onChange={(e: {
                    target: { value: React.SetStateAction<string> }
                  }) => setAdvertiserId(e.target.value)}
                  crossOrigin={undefined}
                />
              </div>
              <div className="mb-1 flex flex-col my-3">
                <Typography
                  placeholder={undefined}
                  variant="h6"
                  className="mb-1 text-sm font-medium text-wellBlueGray-900"
                >
                  Advertiser Name
                </Typography>
                <Input
                  autoFocus
                  type="text"
                  size="lg"
                  value={advertiserName}
                  placeholder="e.g., XYZ Advertising"
                  className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                  labelProps={{
                    className: 'hidden',
                  }}
                  style={{ borderRadius: '8px' }}
                  onChange={(e) => setAdvertiserName(e.target.value)}
                  crossOrigin={undefined}
                />
              </div>
              <div>
                <Button
                  variant="filled"
                  className="!bg-lstnGreen-500 w-full mt-6 flex items-center justify-center"
                  placeholder={undefined}
                  onClick={handleSubmit}
                  loading={loading}
                >
                  <span>Save</span>
                </Button>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      label: 'Infringement Details',
      value: 'infringement-details',
      desc: () => {
        return (
          <div className="flex flex-row flex-wrap xl:justify-start mb-6 items-center gap-4">
            <div className="my-4">
              <Typography
                placeholder={undefined}
                className="text-2xl font-semibold text-wellBlueGray-900"
              >
                Infringement Details
              </Typography>
              <div className="flex items-center md:gap-10 md:my-4 flex-wrap">
                <Radio
                  name="type"
                  color="blue"
                  value="image-only"
                  checked={operation === 'image-only'}
                  onChange={handleChange}
                  label={
                    <div>
                      <Typography
                        color="blue-gray"
                        placeholder={undefined}
                        className="font-medium"
                      >
                        Add Screenshot
                      </Typography>
                    </div>
                  }
                  placeholder="undefined"
                  crossOrigin={undefined}
                  className="flex justify-center items-center w-6 h-6"
                />
                <Radio
                  name="type"
                  color="blue"
                  label={
                    <div>
                      <Typography
                        color="blue-gray"
                        placeholder={undefined}
                        className="font-medium"
                      >
                        Add Incident & Screenshot
                      </Typography>
                    </div>
                  }
                  value="infringement-with-image-details" // Unique value
                  checked={operation === 'infringement-with-image-details'}
                  onChange={handleChange}
                  placeholder="undefined"
                  crossOrigin={undefined}
                  className="flex justify-center items-center w-6 h-6"
                />
                <Radio
                  name="type"
                  color="blue"
                  label={
                    <div>
                      <Typography
                        color="blue-gray"
                        placeholder={undefined}
                        className="font-medium"
                      >
                        Add Ad & Incident & Screenshot
                      </Typography>
                    </div>
                  }
                  value="ad-infringement-with-image" // Unique value
                  checked={operation === 'ad-infringement-with-image'}
                  onChange={handleChange}
                  placeholder="undefined"
                  crossOrigin={undefined}
                  className="flex justify-center items-center w-6 h-6"
                />
              </div>
              {operation == 'image-only' ? (
                <>
                  <Typography
                    placeholder={undefined}
                    className="text-base font-normal text-wellBlueGray-900 my-2"
                  >
                    Use this if ad exists, incident exists, and you only want to
                    add a screenshot.
                  </Typography>
                  <div className="flex flex-row flex-wrap items-center gap-6">
                    <div className="w-96 space-y-4">
                      <div className="mb-1 flex flex-col my-3">
                        <Typography
                          placeholder={undefined}
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-900"
                        >
                          Ad Tracking Url
                        </Typography>
                        <Input
                          autoFocus
                          type="text"
                          size="lg"
                          value={adTrackingUrl}
                          placeholder="e.g., 1234567890"
                          className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          style={{ borderRadius: '8px' }}
                          onChange={(e) => setAdTrackingUrl(e.target.value)}
                          crossOrigin={undefined}
                        />
                      </div>
                      <div className="mb-1 flex flex-col my-3">
                        <Typography
                          placeholder={undefined}
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-900"
                        >
                          Ad Domain Displayed
                        </Typography>
                        <Input
                          autoFocus
                          type="text"
                          size="lg"
                          value={adDomainDisplayed}
                          placeholder="e.g., 1234567890"
                          className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          style={{ borderRadius: '8px' }}
                          onChange={(e) => setAdDomainDisplayed(e.target.value)}
                          crossOrigin={undefined}
                        />
                      </div>
                      <div className="mb-1 flex flex-col">
                        <Typography
                          placeholder={undefined}
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-900"
                        >
                          Country
                        </Typography>
                        <Input
                          autoFocus
                          type="text"
                          size="lg"
                          value={country}
                          placeholder="e.g., US or United States"
                          className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          style={{ borderRadius: '8px' }}
                          onChange={(e: {
                            target: { value: React.SetStateAction<string> }
                          }) => setCountry(e.target.value)}
                          crossOrigin={undefined}
                        />
                      </div>
                      <div className="mb-1 flex flex-col my-3">
                        <Typography
                          placeholder={undefined}
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-900"
                        >
                          Keyword Id
                        </Typography>
                        <Input
                          autoFocus
                          type="text"
                          size="lg"
                          value={keywordId}
                          placeholder="e.g., 1234567890"
                          className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          style={{ borderRadius: '8px' }}
                          onChange={(e) => setKeywordId(e.target.value)}
                          crossOrigin={undefined}
                        />
                      </div>
                      <div className="mb-1 flex flex-col my-3">
                        <Typography
                          placeholder={undefined}
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-900"
                        >
                          incidentId
                        </Typography>
                        <Input
                          autoFocus
                          type="text"
                          size="lg"
                          value={incidentId}
                          placeholder="e.g., 1234567890"
                          className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                          labelProps={{
                            className: 'hidden',
                          }}
                          style={{ borderRadius: '8px' }}
                          onChange={(e) => setIncidentId(e.target.value)}
                          crossOrigin={undefined}
                        />
                      </div>
                      <div>
                        {/* Conditional Message */}
                        {keywordId === '' && errorMessage ? (
                          <p className="text-red-700 text-xs font-normal text-start">
                            {errorMessage}
                          </p>
                        ) : (
                          ''
                        )}
                        <Button
                          variant="filled"
                          className="!bg-lstnGreen-500 w-full mt-2 flex items-center justify-center"
                          placeholder={undefined}
                          onClick={updateInfringementDetails}
                          loading={loading}
                          disabled={
                            btnDisabled.country ||
                            btnDisabled.keywordId ||
                            btnDisabled.incidentId
                          }
                        >
                          <span>Save</span>
                        </Button>
                      </div>
                    </div>
                    <div className="flex-col items-center !w-fit md:pl-6 justify-center relative">
                      <Typography
                        className="text-base font-medium text-lstnBlueGray-900 mb-2"
                        placeholder={undefined}
                      >
                        uploaded Images
                      </Typography>
                      <div className="mb-3 flex justify-center">
                        {imageURL ? (
                          <img
                            src={imageURL?.toString() ?? undefined}
                            width="74px"
                            height="74px"
                            className="rounded-full"
                          />
                        ) : (
                          <img src={AvatarLogo} width="74px" height="74px" />
                        )}
                      </div>
                      <div className="flex justify-center">
                        <input
                          accept="image/*"
                          id="contained-button-file"
                          type="file"
                          hidden
                          onChange={
                            handlePictureChange as unknown as ChangeEventHandler<HTMLInputElement>
                          }
                        />

                        <label
                          htmlFor="contained-button-file"
                          className="block"
                        >
                          <Button
                            placeholder={undefined}
                            variant="outlined"
                            size="sm"
                            onClick={handleUploadButtonClick}
                            loading={isLoading ? isLoading : !userData}
                          >
                            {t('uploadButton.uploadAvatar')}
                          </Button>
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              ) : operation == 'infringement-with-image-details' ? (
                <>
                  <Typography
                    placeholder={undefined}
                    className="text-base font-normal text-wellBlueGray-900 my-2"
                  >
                    Use this if ad exists, and you want to add an incident for
                    it plus a supporting screenshot
                  </Typography>
                  <InfringementWithImageDetail />
                </>
              ) : (
                <>
                  <Typography
                    placeholder={undefined}
                    className="text-base font-normal text-wellBlueGray-900 my-2"
                  >
                    Use this if you want to add an incident for an a new ad that
                    does not exist in database.
                    <br /> This adds an ad, an incident, and the supporting
                    screenshot.
                  </Typography>
                  <AdInfringementWithImage />
                </>
              )}
            </div>
          </div>
        )
      },
    },
    {
      label: 'Find Infringements',
      value: 'find-Infringements',
      desc: () => {
        return (
          <div className="flex flex-col flex-wrap mb-6 min-h-[40rem]">
            <div className="w-full md:w-96 space-y-4 my-4">
              <Typography
                placeholder={undefined}
                className="text-2xl font-semibold text-wellBlueGray-900"
              >
                Find Infringements
              </Typography>
              <form onSubmit={handleFormSubmit}>
                <div className="mb-1 flex flex-col">
                  <Typography
                    placeholder={undefined}
                    variant="h6"
                    className="mb-1 text-sm font-medium text-wellBlueGray-900"
                  >
                    Keyword
                  </Typography>
                  <Input
                    autoFocus
                    type="text"
                    size="lg"
                    name="keyword"
                    onChange={(e: { target: { value: string } }) => {
                      setFindInfringement((prevDetails) => ({
                        ...prevDetails,
                        Keyword: e.target.value!,
                      }))
                    }}
                    placeholder="e.g., Canny"
                    className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
                    labelProps={{
                      className: 'hidden',
                    }}
                    style={{ borderRadius: '8px' }}
                    crossOrigin={undefined}
                  />
                </div>
                <div className="mb-1 flex flex-col my-3">
                  <Typography
                    placeholder={undefined}
                    variant="h6"
                    className="mb-1 text-sm font-medium text-wellBlueGray-900"
                  >
                    Country
                  </Typography>
                  <Selector
                    selectClassName="border border-blue-gray-100 rounded-lg uppercase font-normal w-full"
                    className="w-full"
                    options={countries}
                    selectedValue={findInfringement.findInfringementCountry}
                    onChange={updateSelectedCountry}
                    children={undefined}
                    placeholder={'e.g, US'}
                  />
                </div>
                <div>
                  <Button
                    variant="filled"
                    type="submit"
                    className="!bg-lstnGreen-500 w-full mt-6 flex items-center uppercase justify-center"
                    placeholder={undefined}
                    loading={
                      findInfringement.loading &&
                      findInfringement.incidents.length == 0
                    }
                    disabled={
                      findInfringement.Keyword == '' ||
                      findInfringement.findInfringementCountry == '' ||
                      findInfringement.loading
                    }
                  >
                    <span>search</span>
                  </Button>
                </div>
              </form>
            </div>
            {findInfringement.loading &&
            findInfringement.incidents.length == 0 ? (
              <div className="animate-pulse flex items-center">
                <span className="mr-2">
                  <SearchIcon className=" text-gray-400" />
                </span>
                <Typography
                  placeholder={undefined}
                  className="pr-4 py-1 font-normal"
                >
                  Searching for infringements, please wait...
                </Typography>
              </div>
            ) : findInfringement.incidents.length > 0 ? (
              findInfringement.incidents.map(
                (incident: AdIncidentInfo, index) => {
                  return (
                    <div className="max-w-[880px]" key={index}>
                      <div className="details-container grid grid-cols-1 md:grid-cols-2 gap-10 text-blue-gray-500 shadow bg-white">
                        <div className="col-span-1 flex flex-col gap-4">
                          <Typography
                            placeholder={undefined}
                            className="text-xl font-bold text-blue-gray-900"
                          >
                            Screenshot
                          </Typography>
                          <div className="border-2 gap-2">
                            <img
                              src={incident.signedURL}
                              alt="img"
                              className="w-80 h-auto object-cover cursor-pointer"
                              onClick={() => openModal(incident.signedURL)}
                            />
                          </div>

                          <Typography
                            placeholder={undefined}
                            className="text-xl font-bold mt-4 text-blue-gray-900"
                          >
                            Incident Info
                          </Typography>
                          <div>
                            <div className="text-blue-gray-800 ">
                              <Typography
                                placeholder={undefined}
                                className="pr-4 py-1 font-normal flex flex-col"
                              >
                                <span className="font-medium mr-2">Title</span>
                                <span className="text-blue-gray-500">
                                  {incident.title}
                                </span>
                              </Typography>
                            </div>
                            <div className="flex flex-col text-blue-gray-800">
                              <Typography
                                placeholder={undefined}
                                className="pr-4 py-1 font-normal flex flex-col"
                              >
                                <span className="font-medium mr-2">Source</span>
                                <span className="text-blue-gray-500">
                                  {incident.source ? incident.source : '--'}
                                </span>
                              </Typography>
                              <Typography
                                placeholder={undefined}
                                className="pr-4 py-1 font-normal flex flex-col !items-start"
                              >
                                <span className="font-medium mr-2">
                                  Website URL
                                </span>
                                <span className="text-blue-gray-500">
                                  {incident.websiteURL
                                    ? incident.websiteURL
                                    : '--'}
                                </span>
                              </Typography>
                            </div>
                          </div>
                        </div>

                        <div>
                          <Typography
                            placeholder={undefined}
                            className="text-xl font-bold text-blue-gray-900"
                          >
                            Add Info
                          </Typography>
                          <div className="flex flex-col text-blue-gray-800 mt-1">
                            <Typography
                              placeholder={undefined}
                              className="pr-4 py-2 font-normal"
                            >
                              <span className="font-medium mr-2 block">
                                Description
                              </span>
                              <span className="text-blue-gray-500">
                                {incident.description
                                  ? incident.description
                                  : '--'}
                              </span>
                            </Typography>
                            <Typography
                              placeholder={undefined}
                              className="pr-4 py-2 font-normal"
                            >
                              <span className="font-medium mr-2 block">
                                Ad Domain Displayed
                              </span>
                              <span className="text-blue-gray-500">
                                {incident.adlink
                                  ? incident.adlink
                                  : incident.displayedLink
                                    ? incident.displayedLink
                                    : '--'}
                              </span>
                            </Typography>
                            <Typography
                              placeholder={undefined}
                              className="pr-4 py-2 font-normal"
                            >
                              <span className="font-medium mr-2 block">
                                Tracking URL
                              </span>
                              <span className="break-words text-blue-gray-500">
                                {incident.trackingLink
                                  ? incident.trackingLink
                                  : '--'}
                              </span>
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                },
              )
            ) : findInfringement.errorMessageNotFound !== '' ? (
              <div className="text-base font-normal">
                <Typography
                  placeholder={undefined}
                  className="pr-4 py-1 font-normal flex gap-2"
                >
                  <span>{findInfringement.errorMessageNotFound}</span>
                  <span>Request ID: {findInfringement.errorRequestId}</span>
                </Typography>
              </div>
            ) : null}

            <Dialog
              open={!!selectedImage}
              handler={closeModal}
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
              }}
              placeholder=""
              size="xl"
              style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
            >
              <Close
                style={{
                  position: 'absolute',
                  top: -20,
                  right: -20,
                  color: 'white',
                }}
                onClick={closeModal}
              />
              <DialogBody
                placeholder=""
                style={{ backgroundColor: 'transparent' }}
              >
                {selectedImage ? (
                  <img
                    src={selectedImage}
                    alt="Modal Image"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '80vh',
                      objectFit: 'contain',
                      borderRadius: '10px',
                      margin: 'auto',
                    }}
                  />
                ) : (
                  <></>
                )}
              </DialogBody>
            </Dialog>
          </div>
        )
      },
    },
  ]
  return (
    <div className="flex flex-col sm:pl-0 single-menu-container">
      {' '}
      <div className="w-[400px] md:w-[880px] my-5">
        <Typography
          placeholder={undefined}
          className="text-2xl font-medium text-lstnGray-900 my-5 pl-4"
        >
          Control Panel
        </Typography>
      </div>
      <Tabs value={activeTab}>
        <TabsHeader
          placeholder={undefined}
          className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
          indicatorProps={{
            className:
              'bg-transparent border-b-2 border-well_primary-500 shadow-none rounded-none my-3',
          }}
        >
          {data.map(({ label, value }) => (
            <Tab
              placeholder={undefined}
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={
                activeTab === value
                  ? 'text-gray-900 font-medium my-3 w-fit'
                  : 'w-fit  my-3'
              }
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody placeholder={undefined}>
          {data.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              {desc()}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </div>
  )
}

export default AdminPanel
