import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector, RootState } from '../../store/store'
import { IKeyword } from '../../store/features/keywordSlice'
import axios from 'axios'
import firebase from 'firebase/compat/app'
import Toast from '../Toast/Toast'
import StripeForm from '../Stripe'
import { Typography } from '@material-tailwind/react'
import { database } from '../../firebaseApp'
import { customEvent } from '../../utils/customHooks'

type Selection = {
  [region: string]: {
    isFullySelected: boolean
    countries: string[]
  }
}

const EditKeyword = () => {
  const params = useParams()
  const { keywords } = useAppSelector((store) => store.keyword)
  const { currentProject } = useAppSelector((store) => store.project)
  const { user } = useAppSelector((store: RootState) => store.user)
  const { currentAccount } = useAppSelector((store) => store.account)

  const [loading, setLoading] = useState(false)
  const [keyword, setKeyword] = useState('')
  const [selected, setSelected] = useState<Selection>({})
  const [whitelistedDomain, setWhitelistedDomain] = useState<string>('')

  useEffect(() => {
    if (params.keywordId) {
      const keywordData = keywords.find(
        (keyword) => keyword.id === params.keywordId,
      ) as IKeyword
      if (keywordData) {
        setKeyword(keywordData.title)
        setSelected(keywordData?.countriesToMonitor! as any)
        setWhitelistedDomain(keywordData?.whitelistedDomain!)
      }
    }
  }, [params, params.keywordId, keywords])

  const handleKeywordEdited = () => {
    customEvent('keyword_edited', {
      accountId: currentAccount.id,
      user_userId: user.uid,
      keywordId: params.keywordId,
      projectId: currentProject?.id,
    })
  }

  const handleSubmit = async () => {
    if (!params.keywordId) throw Error('Keyword ID is required')
    if (keyword !== '' && Object.keys(currentProject).length !== 0) {
      Toast({
        title: 'Countries to monitor were successfully edited',
        variant: 'info',
        options: { autoClose: 5000 },
      })
      const token = await firebase?.auth()?.currentUser?.getIdToken()
      await axios
        .post(
          `${process.env.REACT_APP_FIREBASE_API}/keyword/editKeyword`,
          {
            data: {
              projectId: currentProject?.id,
              whitelistedDomain: whitelistedDomain,
              countriesToMonitor: selected,
              id: params.keywordId,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Access-Control-Expose-Headers': 'Content-Disposition',
            },
          },
        )
        .then(() => {
          //   handleKeywordDialog()
          handleKeywordEdited()
          setKeyword('')
          setWhitelistedDomain('')
          setLoading(false)
          Toast({
            title: 'Countries to monitor were successfully edited',
            options: { autoClose: 5000, className: 'custom-toast' },
          })
        })
        .catch((error: any) => {
          //   handleKeywordDialog()
          handleKeywordEdited()
          setLoading(false)
          setKeyword('')
          setWhitelistedDomain('')
          console.log(error)
          Toast({
            title: 'Failed. Something went wrong',
            variant: 'error',
            options: { autoClose: 5000 },
          })
        })
    }
  }

  const handleUpdate = async (id: string, data: IKeyword) => {
    try {
      const keywordRef = database.collection('keywords').doc(id)
      await keywordRef.update({ ...data })
      customEvent('whitelisted_domains_edited', {
        user_userId: user.uid,
        accountId: currentAccount.id,
        projectId: currentProject?.id,
        keywordId: id,
      })
      customEvent('keyword_edited', {
        accountId: currentAccount.id,
        user_userId: user.uid,
        keywordId: params.keywordId,
        projectId: currentProject?.id,
      })
    } catch (error) {
      console.error('keyword update error', error)
    }
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <Typography
          placeholder="Your Projects"
          className="text-2xl text-blue-gray-900 font-medium"
        >
          Edit Keyword
        </Typography>
      </div>
      <StripeForm
        handleSubmit={handleSubmit}
        isEditing={true}
        loading={loading}
        setLoading={setLoading}
        keyword={keyword}
        setKeyword={setKeyword}
        whitelistedDomain={whitelistedDomain}
        setWhitelistedDomain={setWhitelistedDomain}
        selected={selected}
        setSelected={setSelected}
        handleUpdate={handleUpdate}
      />
    </>
  )
}

export default EditKeyword
