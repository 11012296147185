import React, { useCallback, useMemo } from 'react'
import { Menu, MenuHandler, MenuList, MenuItem } from '@material-tailwind/react'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { useTranslation } from 'react-i18next'
import { RootState, useAppSelector } from '../../store/store'
import UserProfileAvatar from '../UserProfileAvatar'
import { IconButton } from '@material-ui/core'
import ImagePlaceholderSkeleton from '../ImagePlaceholderSkeleton'
import SettingsIcon from '@material-ui/icons/Settings'
import { useNavigate } from 'react-router-dom'
import AddToQueueIcon from '@material-ui/icons/AddToQueue'
import { accountRole } from '../../enums'
// import { AddKeyword } from './adminPanel'

export function MenuDefault(): JSX.Element {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { userData, fetchStatusProgress, roles } = useAppSelector(
    (store: RootState) => store.user,
  )
  const { currentAccount } = useAppSelector((store) => store.account)
  // const [open, setOpen] = React.useState(false)

  const settings = useCallback(() => {
    navigate('/settings')
  }, [navigate])
  const accountSettings = useCallback(() => {
    navigate('/account-settings')
  }, [navigate])

  //console.log('roles', roles)
  const verifyAccountRole = useMemo(() => {
    if (roles && currentAccount && currentAccount.users && userData) {
      const currentAccountRole = currentAccount.users[userData.id!]
      const accRoles = roles[currentAccountRole?.role]?.['accountSettings']
      return accRoles
    }
  }, [roles, currentAccount, currentAccount.users, userData])

  const _handleOpen = () => {
    navigate('/admin-dashboard')
  }

  return (
    <>
      <Menu>
        <MenuHandler className="cursor-pointer" data-cy="user-menu">
          <IconButton size="small" disabled={!userData}>
            {!userData ? (
              // <CircularProgress style={{ color: '#04c61d' }} />
              <div style={{ width: '35px', height: '35px' }}>
                <ImagePlaceholderSkeleton />
              </div>
            ) : (
              <UserProfileAvatar
                userName={
                  userData.name
                    ? userData.name
                    : fetchStatusProgress
                      ? 'Profile Picture'
                      : ''
                }
                avatarUrl={String(userData.profilePicture)}
                width="35px"
                height="35px"
              />
            )}
          </IconButton>
        </MenuHandler>
        <MenuList placeholder={undefined}>
          <MenuItem
            placeholder={undefined}
            onClick={settings}
            data-cy="project-settings"
          >
            <AccountCircle /> {t('menu.itemOne')}
          </MenuItem>
          {verifyAccountRole?.includes(accountRole.SEE_ACCOUNT_SETTINGS) ? (
            <MenuItem placeholder={undefined} onClick={accountSettings}>
              <SettingsIcon /> {t('menu.itemTwo')}
            </MenuItem>
          ) : null}
          {userData && userData.isAdmin ? (
            <MenuItem placeholder={undefined} onClick={_handleOpen}>
              <AddToQueueIcon />
              <span className="ml-2">Admin Panel </span>
            </MenuItem>
          ) : (
            ''
          )}
        </MenuList>
      </Menu>
      {/* <AddKeyword openKeywordDialog={open} handleKeywordDialog={_handleOpen} /> */}
    </>
  )
}
