import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Typography,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
} from '@material-tailwind/react'
import { SocialCard } from '../SocialReplies/Card'
import MonitorButton from './monitorButton'
import SearchIcon from '../../icons/search.svg'
import Selector from '../SelectInput/Select'
//import DatePicker from './datePicker'
//import DatePicker from './datePicker'
import { ViolationTable } from './violationTable'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  fetchKeywordSubCollections,
  getKeywordData,
  Iincident,
} from '../../store/features/keywordSlice'
import { AdInfo } from '../../interfaces'
// import { DrawerRight } from './drawer'
import { differenceInDays, fromUnixTime, startOfDay } from 'date-fns'
// import { formatInTimeZone } from '../../utils/customHooks'
import { fetchProductById } from '../../store/features/planSlice'

interface Region {
  isFullySelected: boolean
  countries: string[]
}

const MetaKeyword = () => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [country, setCountry] = useState<string>('all')

  const [status, setStatus] = useState<string>('all')
  const [date] = useState<Date>()
  const [search, setSearch] = useState('')

  // const { keywords } = useAppSelector((store) => store.keyword)
  const { currentKeywordIncidents } = useAppSelector((store) => store.keyword)
  const { currentAccount } = useAppSelector((store) => store.account)
  const { plans } = useAppSelector((store) => store.plan)
  const productsData = useAppSelector((state) => state.plan.product)

  const [incidentsAccordingToCountry, setIncidentsAccordingToCountry] =
    useState<Iincident[]>([])

  const params = useParams()
  const location = useLocation()
  const param = new URLSearchParams(location.search)
  const initialTab = param.get('tab') || 'stats'
  const [activeTab, setActiveTab] = React.useState(initialTab || 'stats')
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // const timestamp = formatInTimeZone(startOfDay(new Date()), 'Europe/Rome')

  const [resolvedStats, setResolvedStats] = useState({
    totalMentions: 0,
    lastWeekResolved: 0,
  })

  const keywordId = params.keywordId
  const keywordSubscriptionStartDate =
    currentAccount && keywordId
      ? currentAccount?.stripeSubscriptionData[keywordId]?.current_period_start
      : null

  const keywordSubscriptionEndDate =
    currentAccount && keywordId
      ? currentAccount?.stripeSubscriptionData[keywordId]?.current_period_end
      : null

  const stripeData = useMemo(() => {
    return currentAccount?.stripeSubscriptionData[keywordId!]
  }, [currentAccount, keywordId])

  useEffect(() => {
    if (currentAccount && keywordId && stripeData?.items?.data) {
      const productIds = stripeData.items.data
        .map((item: { plan: { product: string } }) => item.plan.product)
        .filter(Boolean)
      dispatch(fetchProductById(productIds))
    }
  }, [currentAccount, keywordId, dispatch, stripeData])

  const allCountries = useMemo(() => {
    if (!currentAccount || !keywordId || !stripeData || !productsData) return []

    const productIds = stripeData.items.data
      .map((item: { plan: { product: string } }) => item.plan.product)
      .filter(Boolean)

    const countries = productIds
      .map((productId: string) => {
        const product = productsData[productId]
        if (!product) {
          console.warn(`Product data missing for ID: ${productId}`)
        }
        return product
      })
      .filter(
        (product: { stripe_metadata_countryCode: string; name: string }) =>
          product?.stripe_metadata_countryCode && product?.name,
      )
      .map(
        (product: { stripe_metadata_countryCode: string; name: string }) => ({
          value: product.stripe_metadata_countryCode.toLowerCase(),
          label: product.name,
        }),
      )

    const allCountriesOption = { value: 'all', label: 'All Countries' }
    return [allCountriesOption, ...countries]
  }, [currentAccount, keywordId, productsData, stripeData])

  const daysDifference =
    keywordSubscriptionStartDate &&
    differenceInDays(
      startOfDay(new Date()),
      startOfDay(fromUnixTime(keywordSubscriptionStartDate)),
    )

  const totalDays =
    keywordSubscriptionStartDate &&
    keywordSubscriptionEndDate &&
    differenceInDays(
      fromUnixTime(keywordSubscriptionEndDate as number),
      fromUnixTime(keywordSubscriptionStartDate),
    )

  const openDrawer = (id: string) => {
    navigate(location.pathname + '/' + id)
  }

  useEffect(() => {
    if (params.keywordId) {
      setCountry('all')
      fetchKeywordSubCollections(params.keywordId!, dispatch)
    }
  }, [params, dispatch])

  useEffect(() => {
    //setActiveTab(initialTab)
    if (initialTab === 'violation') {
      setActiveTab('violation')
      setTimeout(() => {
        ;(
          containerRef.current?.querySelector(
            'li[data-value="violation"]',
          ) as HTMLElement
        )?.click()
      }, 0)
    }
  }, [initialTab])

  const countAdsScannedLastWeek = (
    incident: Iincident[] | AdInfo[],
    isAd?: boolean,
  ) => {
    const today = new Date()
    const oneWeekAgo = new Date()
    oneWeekAgo.setDate(today.getDate() - 7)

    return incident.filter((incidentLog) => {
      if (isAd && incidentLog.updatedAt) {
        const updatedAt = incidentLog.updatedAt
        const updatedAtDate =
          updatedAt && updatedAt ? Math.floor(updatedAt * 1000) : null
        const updatedAtDateFormatted = new Date(updatedAtDate!)
        return (
          updatedAtDateFormatted &&
          updatedAtDateFormatted >= oneWeekAgo &&
          updatedAtDateFormatted <= today
        )
      } else {
        const createdAt = incidentLog.createdAt
        const createdAtDate =
          createdAt && createdAt.seconds
            ? new Date(createdAt.seconds * 1000) // Convert seconds to milliseconds
            : null
        return (
          createdAtDate && createdAtDate >= oneWeekAgo && createdAtDate <= today
        )
      }
    }).length
  }

  const getResolvedIncidentStats = (
    incidents: Iincident[],
    countryFilter: string | null = null,
  ) => {
    const today = new Date()
    const oneWeekAgo = new Date()
    oneWeekAgo.setDate(today.getDate() - 7)

    let totalMentions = 0
    let lastWeekResolved = 0

    incidents.forEach((incident) => {
      if (incident.status === 'resolved') {
        if (
          countryFilter ? incident.ad_countries?.includes(countryFilter) : true
        ) {
          totalMentions++
        }
        const lastResolvedLog = incident.statusChangeLog
          ?.filter((log) => log.newStatus === 'resolved')
          .pop()

        if (lastResolvedLog) {
          const resolvedDate =
            lastResolvedLog.updatedAt instanceof Date
              ? lastResolvedLog.updatedAt
              : typeof lastResolvedLog.updatedAt === 'string'
                ? new Date(lastResolvedLog.updatedAt)
                : lastResolvedLog.updatedAt.toDate()

          if (resolvedDate >= oneWeekAgo && resolvedDate <= today) {
            if (
              countryFilter
                ? incident.ad_countries?.includes(countryFilter)
                : true
            ) {
              lastWeekResolved++
            }
          }
        }
      }
    })

    return { totalMentions, lastWeekResolved }
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // const value = event.target?.value?.toLowerCase()
    setSearch(event.target?.value)
  }

  const allStatuses = [
    { value: 'all', label: 'All Statuses' },
    { value: 'reported', label: 'Reported' },
    { value: 'resolved', label: 'Resolved' },
    { value: 'identified', label: 'Identified' },
    { value: 'not an incident', label: 'Not an incident' },
  ]

  const handleStatusChange = async (
    value: string | undefined,
  ): Promise<void> => {
    const st = value || ''
    setStatus(st as string)
  }

  const handleCountryChange = (value: string | undefined): void => {
    const selectedCountry = value
    setCountry(selectedCountry!)
  }

  const handleChangeTab = (value: string) => {
    setActiveTab(value)
    ;(
      containerRef.current?.querySelector(
        'li[data-value="violation"]',
      ) as HTMLElement
    )?.click()
  }

  // const keyword = useMemo(() => {
  //   return params.keywordId
  //     ? keywords.find((keyword) => keyword.id === params.keywordId)
  //     : null
  // }, [params.keywordId, keywords])

  useEffect(() => {
    const fetchKeywordCountriesCode = async () => {
      const getKeywordDetails = await getKeywordData(keywordId!)
      const currentKeywordCountries: Record<string, Region> =
        getKeywordDetails?.countriesToMonitor
      const allCountries = Object.values(currentKeywordCountries).reduce(
        (acc: string[], region: Region) => {
          return acc.concat(region?.countries)
        },
        [],
      )
      if (currentKeywordIncidents && allCountries.includes('all')) {
        setIncidentsAccordingToCountry(currentKeywordIncidents)
        const stats = getResolvedIncidentStats(currentKeywordIncidents)
        setResolvedStats(stats)
      } else {
        let filteredIncidents = currentKeywordIncidents.filter((incident) => {
          if (!incident?.ad_countries) return false

          // Filter for all selected countries or the one selected by the user
          return incident?.ad_countries.some((country: string) => {
            const countryData = plans.find(
              (plan) => plan.name === country.trim(),
            )
            const checkCountry = allCountries.includes(
              countryData?.stripe_metadata_countryCode!,
            )
            return checkCountry
          })
        })

        // If a specific country is selected, filter by that country
        if (country !== 'all') {
          filteredIncidents = filteredIncidents.filter((incident) => {
            return incident?.ad_countries?.some((code: string) => {
              const countryData = plans.find(
                (plan) => plan.name === code.trim(),
              )
              return (
                countryData?.stripe_metadata_countryCode?.toLowerCase() ===
                country
              )
            })
          })
        }

        setIncidentsAccordingToCountry(filteredIncidents)

        const stats = getResolvedIncidentStats(filteredIncidents)
        setResolvedStats(stats)
      }
    }

    fetchKeywordCountriesCode()
  }, [keywordId, currentKeywordIncidents, currentAccount, country, plans])

  const KeywordData = [
    {
      label: 'Overview & Stats',
      value: 'stats',
      desc: () => {
        return (
          <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8 px-0">
            <div className="flex gap-2">
              {/* <SocialCard
                title="ADS SCANNED"
                totalMentions={
                  keyword?.scanData && keyword?.scanData[timestamp]
                    ? keyword.scanData![timestamp]
                    : 0
                }
                lastWeek={`${
                  keyword?.scanData && keyword?.scanData[timestamp]
                    ? keyword.scanData![timestamp]
                    : 0
                }`}
              /> */}
              <SocialCard
                title="INFRINGEMENTS FOUND"
                totalMentions={incidentsAccordingToCountry?.length || 0}
                lastWeek={`${countAdsScannedLastWeek(
                  incidentsAccordingToCountry || [],
                )} Incidents`}
              />
              <SocialCard
                title="INFRINGEMENTS RESOLVED"
                totalMentions={resolvedStats.totalMentions}
                lastWeek={`${resolvedStats.lastWeekResolved} Resolutions`}
              />
            </div>
            <Card
              placeholder={undefined}
              className="w-full border border-lstnBlueGray-50 rounded-lg overflow-hidden my-6 py-4 px-6  h-auto"
            >
              <CardHeader
                placeholder={undefined}
                floated={false}
                className="shadow-none text-center rounded-none mt-0 mx-0 pb-4"
              >
                <Typography
                  color="blue-gray"
                  placeholder={undefined}
                  className="mb-2 text-base font-bold uppercase text-left"
                >
                  Countries Monitored
                </Typography>
              </CardHeader>
              <CardBody placeholder={undefined} className="p-0">
                <div className="ml-[3px] mb-4">
                  {daysDifference !== undefined ? (
                    <div className="flex gap-1">
                      {[...Array(daysDifference + 1)].map((_, index) => {
                        const subscriptionDate = fromUnixTime(
                          keywordSubscriptionStartDate,
                        )
                        if (index <= totalDays) {
                          return (
                            <MonitorButton
                              key={index}
                              day={subscriptionDate.getDate() + index}
                              month={subscriptionDate.getMonth()}
                              incident={incidentsAccordingToCountry}
                              handleChangeTab={handleChangeTab}
                              subscriptionStartDate={subscriptionDate}
                            />
                          )
                        }
                      })}
                    </div>
                  ) : null}
                </div>
                {/* {incidentsAccordingToCountry &&
                currentKeywordIncidents.length >
                  incidentsAccordingToCountry.length ? (
                  <WarningBanner
                    count={
                      currentKeywordIncidents.length -
                      incidentsAccordingToCountry.length
                    }
                  />
                ) : null} */}
              </CardBody>
              <CardFooter placeholder={undefined} className="p-0 ">
                <div className="flex justify-between w-full">
                  <Typography
                    placeholder="Your Projects"
                    className="text-xs text-blue-gray-400 font-semibold w-[10%] text-center"
                  >
                    30 days ago
                  </Typography>
                  <div className="w-[80%] flex items-center">
                    <div className="border-t border-gray-400 w-full" />
                  </div>
                  <Typography
                    placeholder="Your Projects"
                    className="text-xs text-blue-gray-400 font-semibold w-[10%] text-center"
                  >
                    Today
                  </Typography>
                </div>
              </CardFooter>
            </Card>
          </div>
        )
      },
    },
    {
      label: 'Incidents / Violations',
      value: 'violation',
      desc: () => {
        return (
          <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8">
            <div className="mt-2 flex justify-between w-full gap-2">
              <div className="grid gap-2 w-2/3 md:grid-cols-2">
                <Selector
                  selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold w-full"
                  className="w-full"
                  options={allStatuses}
                  selectedValue={status}
                  onChange={handleStatusChange}
                  children={undefined}
                />
                <Selector
                  selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold w-full"
                  className="w-full"
                  options={allCountries}
                  selectedValue={country}
                  onChange={handleCountryChange}
                  children={undefined}
                />
                {/* <DatePicker date={date} setDate={setDate} /> */}
              </div>
              <div className="relative w-1/3 ">
                <img
                  src={SearchIcon}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                />
                <Input
                  type="text"
                  size="md"
                  placeholder="Search"
                  value={search}
                  className="pl-10 w-full !border-lstnBlueGray-100 focus:!border-gray-900 rounded-lg text-blue-gray-400 placeholder:opacity-100"
                  onChange={onSearch}
                  labelProps={{
                    className: 'hidden',
                  }}
                  crossOrigin={undefined}
                />
              </div>
            </div>
            <div className="my-6">
              <ViolationTable
                openDrawer={openDrawer}
                search={search}
                date={date}
                status={status}
                IncidentFiltered={incidentsAccordingToCountry}
              />
            </div>
          </div>
        )
      },
    },
  ]
  return (
    <>
      <Tabs value={activeTab} className="py-6" ref={containerRef}>
        <TabsHeader
          placeholder={undefined}
          className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
          indicatorProps={{
            className:
              'bg-transparent border-b-2 border-well_primary-500 shadow-none rounded-none my-3',
          }}
        >
          {KeywordData.map(({ label, value }) => (
            <Tab
              placeholder={undefined}
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={
                activeTab === value
                  ? 'text-gray-900 font-medium my-3 w-fit'
                  : 'w-fit  my-3'
              }
              // style={{ width: 'fit-content' }}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody placeholder={undefined}>
          {KeywordData.map(({ value, desc }) => (
            <TabPanel key={value} value={value} className="px-0">
              {desc()}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
      {/* <DrawerRight /> */}
    </>
  )
}

export default MetaKeyword
